import Entity from '@/services/entity';
import { NAPEntityName } from '@/services/entity';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: {},
    processing: false,
  },
  getters: {
    byCaseID: (state) => (caseID) => {
      if (state.all[caseID] === undefined) {
        return null;
      } 

      return state.all[caseID];
    },
    activeByCaseID: (state) => (caseID) => {
      if (state.all[caseID] === undefined) {
        return null;
      }

      return state.all[caseID].filter(e => !e.isNew() && !e.hasMarkedAsDeleted());
    }
  },
  actions: {
    setup({ commit }, _case) {
      commit('setup', {
        caseID: _case.id,
        entities: _case.entities.map(e => (new Entity({
          ...e,
          caseID: e.case_id,
          sourceOfFunds: e.funds && e.funds.split(',').map(f => parseInt(f)),
          deductions: (e.deductions && e.deductions.split(',').map(d => parseInt(d))) || []
        })))
      });
    },
    add({ commit }, entity) {
      commit('add', entity);
    },
    update({ commit }, entity) {
      commit('update', entity);
    },
    delete({ commit }, entity) {
      commit('delete', entity);
    },
    async saveChanges({ state, commit }, caseID) {
      commit('setProcessing', true);
      await Promise.all(state.all[caseID].map(e => e.save()));
      state.all[caseID] = state.all[caseID].filter(e => !e.hasMarkedAsDeleted());
      commit('setProcessing', false);
    },
    discardChanges({ commit }, caseID) {
      commit('discardChanges', caseID);
    },
    reset({ commit }) {
      commit('reset');
    }
  },
  mutations: {
    setup(state, { caseID, entities }) {
      Vue.set(state.all, caseID, entities);
    },
    add(state, entity) {
      if (state.all[entity.caseID] === undefined) {
        Vue.set(state.all, entity.caseID, []);
      }

      if (!state.all[entity.caseID].length) {
        state.all[entity.caseID] = [new Entity({
          ...entity,
          name: NAPEntityName,
          share: 100,
          deductions: [],
          sourceOfFunds: [2,3],
          slug: (+new Date()) - 1
        })];
      }
      
      state.all[entity.caseID] = [...state.all[entity.caseID], new Entity(entity)];
    },
    update(state, entity) {
      if (entity.name === NAPEntityName) {
        return;
      }

      state.all[entity.caseID]
        .find(e => e.slug === entity.slug)
        .update(entity);
    },
    delete(state, entity) {
      if (entity.name === NAPEntityName) {
        return;
      }

      let idx = state.all[entity.caseID].findIndex(e => e.slug === entity.slug);
      let item = state.all[entity.caseID][idx];
      item.markAsDeleted();

      if (item.isNew()) {
        state.all[entity.caseID].splice(idx, 1);
      }

      state.all[entity.caseID].forEach(e => e.deductions = e.deductions.filter(d => d !== item.slug))
    },
    discardChanges(state, caseID) {
      state.all[caseID] = state.all[caseID].filter(e => !e.isNew());
      state.all[caseID].forEach(e => e.revertChanges());
    },
    setProcessing(state, value) {
      state.processing = value;
    },
    reset(state) {
      state.all = {};
    }
  },
};
