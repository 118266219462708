import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCommonStore = defineStore('commonStore', () => {
  const isSidebarInitialized = ref(false);
  const caseSellerAccountsTypes = ref([]);
  const isIndividualStatusChange = ref(false);

  return {
    isSidebarInitialized,
    caseSellerAccountsTypes,
    isIndividualStatusChange
  };
});
