const component = path => () => import(`@/components/pages/${path}.vue`)

const routes = [
    {
        path     : "/admin",
        name     : "admin",
        component: component("admin/auth/AdminLogin"),
        meta     : {title: "Artemis | Admin"}
    },
    {
        path     : "/admin/dashboard",
        name     : "admindashboard",
        component: component("admin/Dashboard"),
        meta     : {title: "Admin | Dashboard"}
    },
    {
        path     : "/admin/rightsholder",
        name     : "adminrightsholder",
        component: component("admin/Rightsholder"),
        meta     : {title: "Admin | Rightsholder"}
    },
    {
        path     : "/admin/rightsholder/:rightsholder_id/campaign",
        name     : "adminrightsholdercampaigns",
        component: component("admin/RightsholderCampaign"),
        meta     : {title: "Admin | Rightsholder Campaigns"}
    },
    {
        path     : "/admin/campaigns",
        name     : "admincampaigns",
        component: component("admin/Campaigns"),
        meta     : {title: "Admin | Campaigns"}
    },
    {
        path     : "/admin/campaigns/edit/:campaign_id",
        name     : "admineditcampaign",
        component: component("admin/EditCampaign"),
        meta     : {title: "Admin | Edit Campaign"}
    },
    {
        path     : "/admin/platforms",
        name     : "adminplatforms",
        component: component("admin/Platforms"),
        meta     : {title: "Admin | Platforms"}
    },
    {
        path     : "/admin/sellers",
        name     : "adminsellers",
        component: component("admin/Sellers"),
        meta     : {title: "Admin | Sellers"}
    },
    {
        path     : "/admin/whitelists",
        name     : "adminwhitelists",
        component: component("admin/Whitelists"),
        meta     : {title: "Admin | Whitelisted Sellers"}
    },
    {
        path     : "/admin/sellers/listings",
        name     : "adminsellerlistings",
        component: component("admin/SellersListing"),
        meta     : {title: "Admin | Sellers Listings"}
    },
    {
        path     : "/admin/lf",
        name     : "adminlawfirms",
        component: component("admin/Lawfirms"),
        meta     : {title: "Admin | Lawfirms"}
    },
    {
        path     : "/admin/lf/edit/:lawfirm_id",
        name     : "admineditlawfirm",
        component: component("admin/EditLawfirm"),
        meta     : {title: "Admin | Edit Lawfirm"}
    },
    {
        path     : "/admin/lf/users",
        name     : "adminlfusers",
        component: component("admin/LfUser"),
        meta     : {title: "Admin | Lawfirm Users"}
    },
    {
        path     : "/admin/users",
        name     : "adminartemisusers",
        component: component("admin/ManageUser"),
        meta     : {title: "Admin | Artemis Users"}
    },
    {
        path     : "/admin/users/teams",
        name     : "adminteams",
        component: component("admin/ArtemisTeams"),
        meta     : {title: "Admin | Artemis Teams"}
    },
    // {
    //     path:"/admin/settings",
    //     name:"adminmanagereports",
    //     component:lazyload("admin/ManageReport"),
    //     meta:{title:"Admin | Reports Management"}
    // },
    {
        path     : "/admin/notifications",
        name     : "adminnotifications",
        component: component("admin/Notifications"),
        meta     : {title: "Admin | Notifications"}
    },
    {
        path     : "/admin/account-settings",
        name     : "user-profile-admin",
        component: component("admin/UserProfileAdmin"),
        meta     : {title: "Admin | Account Settings"}
    },
    {
        path     : "/admin/settings/reasons",
        name     : "adminreasons",
        component: component("admin/Reasons"),
        meta     : {title: "Admin | Predefined Reasons"}
    },
// -----------------------------------------------------------------------------
    /**
     * Item and Classes Management Routes
     * 
     * 3 Files
     *  @type { Campaign } -> ItemCampaignsClassifications.vue
     *  @type { Classes } -> ItemsClassifications.vue
     *  @type { Item Types } -> ItemCampaignsClassifications.vue
     */
    
    /**
     * Campaigns Routes
     */
    {
        // All Campaigns
        path     : "/admin/settings/campaigns",
        name     : "adminitemcampaigns",
        component: component("admin/ItemCampaignsClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },
    {
        // All Classes under specific Campaign
        path     : "/admin/settings/campaigns/:campaignID",
        name     : "adminitemcampaigns_classification",
        component: component("admin/ItemsClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },
    {
        // All Item Types under specific Classes
        path     : "/admin/settings/campaigns/:campaignID/:classification_id",
        name     : "adminitemcampaigns_itemtypes",
        component: component("admin/ItemTypesClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },

    /**
     * Classification Routes
     */
    {
        path     : "/admin/settings/classifications",
        name     : "adminitemclassification",
        component: component("admin/ItemsClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },
    {
        path     : "/admin/settings/classifications/:classification_id",
        name     : "adminitemclassification_itemtypes",
        component: component("admin/ItemTypesClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },

    /**
     * Item Types Routes
     */
    {
        path     : "/admin/settings/itemTypes",
        name     : "adminitemtypes",
        component: component("admin/ItemTypesClassifications"),
        meta     : {title: "Admin | Item Classifications Management"}
    },


// -----------------------------------------------------------------------------
    {
        path     : "/admin/settings/dummies",
        name     : "admindummies",
        component: component("admin/Dummies"),
        meta     : {title: "Admin | Dummy Management"}
    },
    {
        path     : "/admin/settings/datapriority",
        name     : "admindatapriority",
        component: component("admin/DataPriority"),
        meta     : {title: "Admin | Data Priority"}

    },

    /**
     * Admin Secret Routes
     */
    {
        path     : "/admin/settings/deployment",
        name     : "admindeploymentpage",
        component: component("admin/DeploymentPage"),
        meta     : {title: "Admin | Deployment Page"}
    },

    {
        path     : "/admin/accounting-report",
        name     : "adminaccountingreports",
        component: component("admin/CampaignAccountReportsSummary"),
        meta     : {title: "Admin | Accounting Report"}
    },
    {
        path     : "/admin/accounting-report/:campaign_id",
        name     : "admincampaignaccounting",
        component: component("admin/AccountingReport"),
        meta     : {title: "Admin | Campaign Accounting Report"}
    },
    {
        path     : "/admin/report/data-analyses-report-generator",
        name     : "admin3monthreport",
        component: component("admin/Report3MonthGenerator"),
        meta     : {title: "Admin | Data Analyses Report Generator"}
    },
    {
        path     : "/admin/report/3-month-report-generator/download/:generated_report_id",
        name     : "admin3monthreportdownload",
        component: component("admin/Report3MonthGeneratorDownload"),
        meta     : {title: "Admin | 3 Month Report Generator Download"}
    },
    {
        path     : "/admin/report/infographic-report",
        name     : "admininfographicreport",
        component: component("admin/InfographicReport"),
        meta     : {title: "Admin | Campaign Infographic Report"}
    },
    {
        path     : "/admin/documentation",
        name     : "admindocumentation",
        component: component("admin/Documentation"),
        meta     : {title: "Admin | End Points Documentation"}
    },
    {
        path     : "/admin/error-logs",
        name     : "adminerrorlogs",
        component: component("admin/ErrorLogs"),
        meta     : {title: "Admin | Error Logs"}
    },
    {
        path     : "/admin/settings/payment-methods",
        name     : "adminpaymentmethods",
        component: component("admin/PaymentMethods"),
        meta     : {title: "Admin | Payment Methods"}
    },
    {
        path     : "/admin/settings/shipping-locations",
        name     : "adminshippinglocations",
        component: component("admin/ShippingLocations"),
        meta     : {title: "Admin | Shipping Locations"}
    },
    {
        path     : "/admin/settings/territory",
        name     : "adminterritory",
        component: component("admin/Territory"),
        meta     : {title: "Admin | Territory"}
    },
    {
        path     : "/admin/settings/infringement-indicators",
        name     : "admininfringementindicators",
        component: component("admin/InfringementIndicators"),
        meta     : {title: "Admin | Infringement Indicators"}
    },
    {
        path     : "/admin/settings/defaults/evidence-order",
        name     : "admindefaultevidenceorder",
        component: component("admin/Defaults"),
        meta     : {title: "Admin | Evidence Order"}
    },
    {
        path     : "/admin/settings/defaults/shipping-location",
        name     : "admindefaultshippinglocation",
        component: component("admin/Defaults"),
        meta     : {title: "Admin | Shipping Locations"}
    },
    {
        path     : "/admin/settings/defaults/claim-type",
        name     : "admindefaultclaimtype",
        component: component("admin/Defaults"),
        meta     : {title: "Admin | Claim Types"}
    },
    {
        path     : "/admin/environment-access-management",
        name     : "adminenvaccessmanagement",
        component: component("admin/EnvironmentAccess"),
        meta     : {title: "Admin | Environment Access Restriction Management"}
    },
    {
        path     : "/admin/reports/researchers-output",
        name     : "adminresearchersoutput",
        component: component("admin/ResearchersOutput"),
        meta     : {title: "Admin | Researchers Output"}
    },
    {
        path     : "/admin/reports/campaign-report",
        name     : "admincampaignreport",
        component: component("admin/Reports"),
        meta     : {title: "Admin | Campaign Report"}
    },
    {
        path     : "/admin/reports/user-weekly-assignment",
        name     : "adminweeklyreport",
        component: component("admin/ReportWeekly"),
        meta     : {title: "Admin | User Weekly Reports Assignment"}
    },
    {
        path     : "/admin/reports/campaign-weekly-assignment",
        name     : "admincampaignweeklyreport",
        component: component("admin/ReportWeeklyCampaign"),
        meta     : {title: "Admin | Campaign  Reports Assignment"}
    },
    {
        path     : "/admin/reports/payment-account-reports",
        name     : "adminpaymentreports",
        component: component("admin/PaymentAccountReports"),
        meta     : {title: "Admin | Payment Accounts Reports"}
    },
    {
        path     : "/admin/reports/data-checker-report",
        name     : "admindatacheckerreport",
        component: component("admin/ReportDataChecker"),
        meta     : {title: "Admin | Data Checker Report"}
    },
    {
        path     : "/admin/auto-campaign-assignment",
        name     : "adminautocampaignassignment",
        component: component("admin/AutoCampaignAssignment"),
        meta     : {title: "Admin | Auto Campaign Assignment"}
    },
    // auto capture
    {
        path     : "/admin/auto-capture-management",
        name     : "adminautocapturemanagement",
        component: component("admin/AutoCaptureManagement"),
        meta     : {title: "Admin | Auto Capture Management"}
    },
    //status override
    {
        path:"/admin/status/status-override",
        name:"adminstatusoverride",
        component: component("admin/StatusOverride"),
        meta: {title: ""}
    },
    {
        path     : "/admin/weekly-quota",
        name     : "adminautocampaignassignmentweeklyquota",
        component: component("admin/WeeklyQuota"),
        meta     : {title: "Admin | Weekly Quota"}
    },
    {
        path     : "/admin/history-logs",
        name     : "adminhistorylogs",
        component: component("admin/HistoryLogs"),
        meta     : {title: "Admin | History Logs"}
    },
    {
        path     : "/admin/feature-access/features",
        name     : "adminFeatures",
        component: component("admin/feature-access/Feature"),
        meta     : {title: "Admin | Feature Access"}
    },
    {
        path     : "/admin/feature-access/user-restrictions",
        name     : "adminUserRestrictions",
        component: component("admin/feature-access/UserRestriction"),
        meta     : {title: "Admin | User Restrictions"}
    },
    {
        path     : "/admin/feature-access/role-based-restrictions",
        name     : "adminRoleBasedRestrictions",
        component: component("admin/feature-access/RoleBasedRestriction"),
        meta     : {title: "Admin | Role-based Restrictions"}
    },
    {
        path     : "/admin/whou",
        name     : "adminnosession",
        component: component("NoSession"),
        meta     : {title: "Admin | Session Expired"}
    },
    {
        path     : '/admin/404',
        name     : 'adminpagenotfound',
        component: () => import('@/components/layouts/PageNotFound'),
        meta     : {title: "Admin | Error 404"}
    },
    {
        path     : "/admin/holdup",
        name     : "adminnotauthorized",
        component: component("NotAuthorized"),
        meta     : {title: "Admin | Not Authorized"}
    },
    {
        path     : "/admin/*",
        redirect : "/admin/404"
    },

]

export default routes
