// @ts-check

import axios from "axios";
import { _FETCH } from "./common"
import { getToken, getAccessLevel } from "./session";
import { AccessLevel } from "./constants";

export const useI18n = async () => {
  /** @type { string } */
  const token = getToken();
  if (token ===  null) {
    return;
  }

  /** @type { number } */
  const accessLevel = getAccessLevel();
  if (accessLevel !== AccessLevel.Client) {
    return;
  }

  const { status, data } = await axios.get(`${process.env.VUE_APP_URL}/api/translations`, { headers: { Authorization: `Bearer ${token}`}})

  if (status !== 200) {
    return;
  }

  const translations = data.data;

  /** @type {{ ja: { [key: string]: string }; en: { [key: string]: string } }} */
  const message = { ja: {}, en: {}};

  for (const { ja, en } of translations) {
    message.ja[en] = ja;
    message.en[en] = en;
  }

  /** @type {import('../main').VueApp } */
  // @ts-ignore
  const vue = window.vm;

  vue.$i18n.setLocaleMessage('ja', message.ja);
  vue.$i18n.setLocaleMessage('en', message.en);
}
