import { Model } from '@vuex-orm/core';
import PlatformMatchingPattern from './platformMatchingPattern';

export default class Platform extends Model {
    static entity = 'platforms';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(null),
            url: this.string(null),
            status: this.number(null),
            platform_matching_patterns: this.hasMany(PlatformMatchingPattern, 'platform_id')
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/platforms',
        dataKey: 'data'
    }
}