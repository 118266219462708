/**
 * User Role.
 * 
 * @readonly
 * @enum { number }
 */
 export const Role = Object.freeze({
  Researcher  : 1,
  QA          : 2,
  Lawyer      : 3,
  Admin       : 4,
  CampaignLead: 5,
  Client      : 6,
  SuperAdmin  : 7
})

export const RoleWithKeys = [
  { id: 1, name: 'Researcher',      hasCmAccess : false, hasVerAccess: true  },
  { id: 2, name: 'Quality Analyst', hasCmAccess : false, hasVerAccess: true  },
  { id: 3, name: 'Lawyer',          hasCmAccess : true,  hasVerAccess: false },
  { id: 4, name: 'Admin',           hasCmAccess : false, hasVerAccess: true  },
  { id: 5, name: 'Campaign Lead',   hasCmAccess : false, hasVerAccess: true  },
  { id: 6, name: 'Client',          hasCmAccess : true,  hasVerAccess: false },
  { id: 7, name: 'Super Admin',     hasCmAccess : true,  hasVerAccess: true  },
];

/**
 * @readonly
 * @enum { number }
 */
export const AccessLevel = Role

/**
 * @readonly
 * @enum { number }
 */
export const ListingStatus = Object.freeze({
  New                   : 0,
  Researched            : 1,
  Recheck               : 2,
  ForRework             : 2,
  Qualified             : 3,
  WIP                   : 4,
  Reworked              : 8,
  Monitoring            : 31,
  Hacked                : 30,
  AutoQualified         : 30,
  Accepted              : 32,
  Rejected              : 33,
  Filed                 : 34,
  Submitted             : 35,
  ForInternalReview     : 36,
  InitialReviewCompleted: 37,
  Recycled              : 38,
  ForPurchasing         : 41,
  Archived              : 66,
  HasPotential          : 98,
  Invalid               : 99
})

export const GUI = location.href.includes('/lawfirm') 
  ? 'lawfirm' : ( location.href.includes('/verification') 
  ? 'ver' : 'admin' );
