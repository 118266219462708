import axios from "axios";
import { buildHeader } from "@/utils/common";
import { trimSlash } from '@/utils/string'
import qs from 'qs'

export const createAuthenticatedAxios = (baseURL) => {
    const axiosInstance = axios.create({baseURL: process.env.VUE_APP_URL});
    axiosInstance.interceptors.request.use(function(config) {
        config.baseURL = `${trimSlash(baseURL)}/${trimSlash(config.baseURL)}`;
        config.headers = {
            ...buildHeader({}),
            ...config.headers
        };
        return config;
    })
    return axiosInstance;
}

export const addDefaultParamsSerializer = (axiosInstance) => {
    axiosInstance.defaults.paramsSerializer = function(params) {
        return qs.stringify(params, { encode: false });
    }
    
    return axiosInstance;
}