// @ts-check

import { until } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFeatureAccessStore = defineStore('featureAccessStore', () => {
  const isUserRestrictedFeatureUpdated = ref(false);
  const isRoleRestrictedFeatureUpdated = ref(false);
  /** @type { Ref<string | number> } */
  const userIdToBeRestricted = ref(null);
  /** @type { Ref<string | number> } */
  const roleIdToBeRestricted = ref(null);
  const isDoneFetching = ref(false);
  const isFeatureRestrictionsReady = until(isDoneFetching).toBe(true);

  /** @type { Ref<string[]> } */
  const restrictedFeatures = ref(['*']);

  /** @type { Ref<Feature[]> } */
  const features = ref([]);

  return {
    isUserRestrictedFeatureUpdated,
    isRoleRestrictedFeatureUpdated,
    userIdToBeRestricted,
    roleIdToBeRestricted,
    features,
    restrictedFeatures,
    isDoneFetching,
    isFeatureRestrictionsReady
  }
});

/**
 * @typedef { object } Feature
 * @property { number } id
 * @property { number } [parent_id]
 * @property { 'verification' | 'case management' | 'admin' } gui
 * @property { string } page_name
 * @property { string } name
 * @property { string } type
 * @property { string } class_name
 * @property { string } description
 * @property { 0 | 1 } is_required
 * @property { 0 | 1 } is_active
 */
