import AsyncModel from './asyncModel';

export default class preFilingGroup extends AsyncModel {
    static entity = 'pre_filing_groups';

    constructor(model, config) {
        super(model, config);
    }

    static fields() {
        return {
            name: this.string(null),
            description: this.string(null),
            campaign_id: this.number(null),
            listings_count: this.number(0)
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/pre_filing_groups',
        dataKey: 'data'
    }
}