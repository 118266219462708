import AccountingInfo from '../../services/accountingInfo';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    byCaseID: (state) => (id) => {
      return state.all[id];
    }
  },
  mutations: {
    add(state, _case) {
      Vue.set(state.all, _case.id, new AccountingInfo(_case));
    },
    reset(state) {
      state.all = {};
    }
  },
};
