import CustomCache from '@/helpers/customCache';
import store from '@/store';
import { SourceOfFundFactory } from './fund'
import Vue from 'vue';

export const NAPEntityName = "NAP Share";

export default class Entity extends CustomCache {
  constructor(entity) {
    super();

    this.caseID = entity.caseID;

    this.id = entity.id;
    this.slug = entity.slug || +new Date();
    this._markAsDeleted = false;
    this.update(entity);
    super.clearCache();
  }

  update(entity) {
    this.name = entity.name;
    this.share = entity.share;
    this.deductions = entity.deductions;
    this.sourceOfFunds = entity.sourceOfFunds;
  }

  isNew() {
    return this.id === null || this.id === undefined;
  }

  hasMarkedAsDeleted() {
    return this._markAsDeleted;
  }

  markAsDeleted() {
    this._markAsDeleted = true;
  }

  getDeductionsSlug() {
    if (this.name === NAPEntityName) {
      return store.getters['entity/activeByCaseID'](this.caseID)
                .filter(e => e.name !== NAPEntityName)
                .map(e => e.slug);
    }
    
    return this.deductions;
  }

  calculateAndGetDistributionAmount(amounts) {
    return this.sourceOfFunds.map(f => {
      let fundFactory = SourceOfFundFactory.getInstance(f);
      let fund = fundFactory.getFund(this.caseID);
      let type = fund.getType();
      return fund.calculateAndGetDistributionAmount(amounts[type], fundFactory.gross, this)
    }).reduce((prev, current) => prev + current);
  }

  async save() {
    if (this._markAsDeleted) {
      await Vue.prototype.$http.delete(
        `/distribution_entity_shares/${this.id}`,
        window.vm.$session.get('admin_headers')
      );
    }
    else if (this.isNew()) {
      let response = await Vue.prototype.$http.post(
        '/distribution_entity_shares',
        this._getRequestBody(),
        window.vm.$session.get('admin_headers')
      );
      this.id = parseInt(response.data.data.id);
    }
    else if (this.hasChanges) {
      await Vue.prototype.$http.patch(
        `/distribution_entity_shares/${this.id}`,
        this._getRequestBody(),
        window.vm.$session.get('admin_headers')
      );
    }

    super.clearCache();

    return true;
  }

  _getRequestBody() {
    return {
      case_id: this.caseID,
      name: this.name,
      slug: this.slug,
      share: this.share,
      funds: this.sourceOfFunds.join(),
      deductions: this.deductions && this.deductions.length > 0
                    ? this.deductions.join()
                    : null
    };
  }
}