import Fund from './fund';

export const LF_FLAT_FEE_AMOUNT = 10000;
export const LF_FLAT_FEE_SLUG = 0;

export default class SettlementFund extends Fund {
  constructor(_case) {
    super({
      caseID: _case.id,
      totalCreditAmount: _case.totalCreditSettlementAmount,
      totalExpensesAmount: _case.totalExpensesSettlementAmount,
      currentExpensesAmount: _case.currentExpensesSettlementAmount,
      totalDistributedAmount: _case.totalDistributedSettlementAmount,
      entities: _case.entities.map(e => ({id: e.id, totalDistributedAmount: e.totalDistributedSettlementAmount}))
    });
  }

  getDeductionAmount(amount, slug) {
    if (slug === LF_FLAT_FEE_SLUG) {
      return LF_FLAT_FEE_AMOUNT;
    }

    return super.getDeductionAmount(amount, slug);
  }

  distribute(amount, entity) {
    this.resetCurrentExpensesAmount();
    super.distribute(amount, entity);
  }

  getType() {
      return "settlement";
  }
}
