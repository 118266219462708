import Campaigns from "@/components/pages/lawfirm/Campaigns";
const component = path => () => import(`@/components/pages/${path}.vue`)

const routes = [
    {//For Lawfirm
        path     : "/lawfirm",
        name     : "lawfirm",
        // component:LawfirmLogin,
        component: component("lawfirm/auth/LawfirmLogin"),
        meta     : {title: "Artemis | Case Management"}
    },
    {
        path     : "/lawfirm/dashboard",
        name     : 'lawfirmdashboard',
        // component:ListingsReview,
        component: component("lawfirm/Dashboard"),
        meta     : {title: "Case Management | Dashboard"}
    },
    {
        path     : "/lawfirm/listings/review",
        name     : 'listingreview',
        // component:ListingsReview,
        component: component("lawfirm/ListingsReview"),
        meta     : {title: "Case Management | Listing Review"}
    },
    {
        path     : "/lawfirm/campaigns",
        name     : 'lawfirmcampaigns',
        // component:Campaigns,
        component: Campaigns,
        meta     : {title: "Case Management | Campaigns"}
    },
    {
        path     : "/lawfirm/listings/campaign/:campaign_id/:status/:case_id",
        name     : "lawfirmlisting",
        // component:Listings,
        component: component("lawfirm/Listings"),
        meta     : {title: "Case Management | My Listings"},
        props    : true
    },
    {
        path    : "/lawfirm/campaign/:campaign_id/listing/:lid",
        name    : "lawfirmeditlisting",
        component: component('lawfirm/CMEditListing'),
        meta    : {title: "Case Management | Edit Listing"}
    },
    {
        path     : "/lawfirm/campaign/:campaign_id/case/:action/:case_id",
        name     : "lawfirmnewcase",
        // component:NewCase,
        component: component("lawfirm/NewCase"),
        meta     : {title: "Case Management | Case Action"},
        props    : true
    },
    {
        path     : "/lawfirm/:campaign_id/case/:case_id/listing/:listing_id/edit",
        name     : "lawfirmeditcaselisting",
        // component:EditCaseListing,
        component: component("lawfirm/EditCaseListing"),
        meta     : {title: "Case Management | Edit Case Listing"},
        props    : true
    },
    {
        path     : "/lawfirm/exportToken/:exportToken",
        name     : "lawfirmexport",
        component: component("lawfirm/Download"),
        meta     : { title: "Case Management | Getting ready to download" },
        props    : true
    },
    {
        path     : "/lawfirm/notifications",
        name     : "lawfirmnotifications",
        component: component("lawfirm/Notifications"),
        meta     : {title: "Case Management | Notifications"}
    },
    {
        path     : "/lawfirm/account-settings",
        name     : "user-profile-lf",
        component: component("lawfirm/UserProfileLF"),
        meta     : {title: "Case Management | Account Settings"}
    },
    {
        path     : "/lawfirm/frozen-account-compiler",
        name     : "lawfirmfrozenaccountcompiler",
        component: component("lawfirm/FrozenAccountCompiler"),
        meta     : {title: "Case Management | Frozen Account Compiler"}
    },
    {
        path     : "/lawfirm/counterfeit-checker",
        name     : 'lawfirmcounterfeitchecker',
        component: component("lawfirm/CounterfeitChecker"),
        meta     : {title: "Case Management | Counterfeit Checker"}
    },
    {
        path     : "/lawfirm/counterfeit-checker/:campaign_id",
        name     : 'lawfirmcampaigncounterfeitchecker',
        component: component("lawfirm/CampaignCounterfeitChecker"),
        meta     : {title: "Case Management | Counterfeit Checker"}
    },
    {
        path     : "/lawfirm/submissions-pack-monitoring",
        name     : 'lawfirmsubmissionspackmonitoring',
        component: component("lawfirm/SubmissionsStatusMonitoring"),
        meta     : {title: "Case Management | Submissions Pack Status Monitoring"}
    },
    {
        path     : "/lawfirm/case-files-explorer",
        name     : 'lawfirmcasefilesexplorer',
        component: component("lawfirm/CaseFilesExplorer"),
        meta     : {title: "Case Management | Case Files Explorer"}
    },
    {
        path     : "/lawfirm/authorize/files-explorer/all",
        name     : 'lawfirmcasefilesexplorerall',
        component: component("lawfirm/CaseFilesExplorer"),
        meta     : {title: "Case Management | Case Files Explorer"}
    },
    //HTTP pages
    {
        path     : "/lawfirm/whou",
        name     : "lawfirmnosession",
        component: component("NoSession"),
        meta     : {title: "Case Management | Session Expired"}
    },
    {
        path     : "/lawfirm/holdup",
        name     : "lawfirmnotauthorized",
        component: component("NotAuthorized"),
        meta     : {title: "Case Management | Not Authorized"}
    },
    {
        path     : '/lawfirm/404',
        name     : 'lawfirmpagenotfound',
        component: ()=> import('@/components/layouts/PageNotFound'),
        meta     : {title: "Case Management | Error 404"}
    },
    {
        path     : "/lawfirm/*",
        redirect : "/lawfirm/404"
    },
]

export default routes
