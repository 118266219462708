//@ts-check

import { defineStore } from 'pinia';
import { useBrowserLocation, useStorage } from '@vueuse/core';
import { computed, ref } from 'vue';

export const useSessionStore = defineStore('sessionStore', () => {
  const isVerLoggedIn = useStorage('isVerLoggedIn', false);
  const isCMLoggedIn = useStorage('isCMLoggedIn', false);
  const isAdminLoggedIn = useStorage('isAdminLoggedIn', false);

  /**
   * @typedef { Object } Session
   * @property { string } userId;
   * @property { string } roleId;
   * @property { string } token;
   * 
   */

  /** @type { Ref<Session> } */
  const verSession = ref(null);
  /** @type { Ref<Session | null> } */
  const adminSession = ref(null);
  /** @type { Ref<Session | null> } */
  const cmSession = ref(null);

  const location = useBrowserLocation();
  const currentGUI = computed(() => {
    /** @type { 'admin' | 'lawfirm' | 'verification' } */
    // @ts-ignore
    const gui = location.value.pathname.split('/').at(1);
    return gui;
  });

  return {
    isAdminLoggedIn,
    isCMLoggedIn,
    isVerLoggedIn,
    currentGUI,
    verSession,
    adminSession,
    cmSession
  };
})
