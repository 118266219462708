/*
|--------------------------------------------------------------------------
| Exportable Common Utility functions
|--------------------------------------------------------------------------
| Here is where you can create functions that you think can be use often.
| To reduce code redundancy. Also, please provide comments that describe 
| the functions. See jsdoc.app for documentation.
|
*/
import { ucFirst } from "@/utils/string";
import { getGuiSession, HEADERS, CURRENT_USER_INFO } from "@/utils/session";
import { AxiosResponse } from 'axios';
import { useFeatureAccessStore } from '../store/pinia/feature-access';

/**
 * Check if given value is empty
 * ```js
 * //Example
 * isEmpty('John Doe') //false
 * isEmpty('  ') //true
 * isEmpty([]) //true
 * isEmpty({}) //true
 * isEmpty(undefined) //true
 * ```
 * @param {any} value Any
 * @return {boolean} Boolean
 */
export const isEmpty = value => {
    try {
        const type = value?.constructor?.name
        if(type === 'String')
            return value.trim() === ''

        if(type === 'Array')
            return value.length === 0

        const isObj = value !== null && value?.constructor?.name === 'Object'
        if(isObj)
            return Object.keys(value).length === 0

        return [null, undefined].includes(value)
    } catch (e) {
        throw new Error(e.message)
    }
}

/**
 * Get pixel perfect image :P
 * @param {string} type
 * @param {string} name
 * @returns {string}
 */
export const getImage = (type, name) => {
    if (name === undefined || isEmpty(name))
        return name

    if (type === 'users') {
        if(name === 'Administrator')
            name = 'Admin'

        if(name === 'Super Admin')
            name = 'Super'

        if(name === 'Campaign Lead')
            name = 'CL'

        return `/static/svg/User_${name}.svg`
    }

    name = ucFirst(name).replace(/\(.+?\)/g, ' ')
        .replace(/[^a-z0-9+]+/gi, ' ')
        .replace(/\s/g, '')

    return `${process.env.VUE_APP_URL}/jpg/${type}/${name}.jpg`
}

/**
 * Object of qflag ids and their name
 * @returns {{
    0 : 'New',
    1 : 'Researched',
    2 : 'ForRework',
    3 : 'Qualified',
    5 : 'Ordered',
    6 : 'Delivered',
    7 : 'SentMail',
    8 : 'Reworked',
    9 : 'Sold Out',
    10: 'WIP',
    11: 'Reassigned',
    30: 'Hacked',
    31: 'Monitoring',
    32: 'Accepted',
    33: 'Rejected',
    34: 'Filed',
    35: 'Submitted',
    36: 'Review',
    97: 'Paid',
    98: 'HasPotential',
    99: 'Invalid'
}}
 */
export const qflags = () => ({
    0 : 'New',
    1 : 'Researched',
    2 : 'ForRework',
    3 : 'Qualified',
    4 : 'Work In Progress',
    5 : 'Ordered',
    6 : 'Delivered',
    7 : 'SentMail',
    8 : 'Reworked',
    9 : 'Sold Out',
    10: 'WIP',
    11: 'Reassigned',
    30: 'Hacked',
    31: 'Monitoring',
    32: 'Accepted',
    33: 'Rejected',
    34: 'Filed',
    35: 'Submitted',
    36: 'Review',
    37: 'InitialReviewCompleted',
    38: 'Recycled',
    66: 'Archived',
    97: 'Paid',
    98: 'HasPotential',
    99: 'Invalid',
})

export const _qflags = ({
    0 : 'New',
    1 : 'Researched',
    2 : 'ForRework',
    3 : 'Qualified',
    4 : 'Work In Progress',
    5 : 'Ordered',
    6 : 'Delivered',
    7 : 'SentMail',
    8 : 'Reworked',
    9 : 'Sold Out',
    10: 'WIP',
    11: 'Reassigned',
    30: 'Hacked',
    31: 'Monitoring',
    32: 'Accepted',
    33: 'Rejected',
    34: 'Filed',
    35: 'Submitted',
    36: 'Review',
    37: 'InitialReviewCompleted',
    38: 'Recycled',
    66: 'Archived',
    97: 'Paid',
    98: 'HasPotential',
    99: 'Invalid',
})

/**
 * Get the id of Qflag name
 * @param {string} flag
 * @returns {number} Qflag Id
 */
export const getQFlagId = flag => {
    const qflags = {
        'new'                : 0,
        'researched'         : 1,
        'for rework'         : 2,
        'for review'         : 3,
        'forreview'          : 3,
        'qualified'          : 3,
        'work in progress'   : 4,
        'ordered'            : 5,
        'delivered'          : 6,
        'sent mail'          : 7,
        'reworked'           : 8,
        'sold out'           : 9,
        'preverified'        : 10,
        'reassigned'         : 11,
        'auto qualified'     : 30,
        'monitoring'         : 31,
        'accepted'           : 32,
        'rejected'           : 33,
        'filed'              : 34,
        'submitted'          : 35,
        'for-internal-review': 36,
        'initial-review-completed': 37,
        'recycled'           : 38,
        'paid'               : 97,
        'has potential'      : 98,
        'invalid'            : 99,
        'archived'           : 66,
    }

    if (flag === 'Client Listing') {
        return;
    }

    if(qflags?.[flag.toLowerCase()] === undefined)
        throw new Error('[' + flag + '] Given flag has no id')

    return qflags[flag.toLowerCase()]
}

/**
 * Get the name of provided qflag id
 * @param {number} id
 * @returns {string} Qflag name
 */
export const getQFlag = id => {
    const _qflags = qflags()

    if(_qflags[id] === undefined)
        throw new Error('given id has no flag')

    return _qflags[id]
}

/**
 * Get the icon directory of QFlag icon
 * @param {number} id
 * @returns {string} Icon directory
 */
export const getQFlagIcon = id => {
    const _qflags = qflags()

    if(_qflags[id] === undefined)
        throw new Error('Given id has no icon')

    return `/static/svg/Icon_${_qflags[id]}.svg`
}

export const toUSD = (amount) => {
    amount = +amount;
    let result = (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return amount < 0
            ? `(${result})`
            : result;
}

export const filterObj = (obj, predicate) => {
    return Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => (res[key] = obj[key], res), {});
}

export const getUserFullName = (user) => {
    return `${user.first_name || ''} ${user.last_name || ''}`.trim();
}

export const getMasterComponent = () => {
    return window.vm.$root.$children[0].$children.filter(child => child.$vnode.componentOptions.tag == "ar-master")[0];
}

export const showSnackbar = (text = null, loading = false, type = "default", icon = "/static/img/V-Loader.gif", closable = false, user = "", time = "just now") => {
    getMasterComponent().showSnackbar(text, loading, type, icon, closable, user, time);
}

export const showBackdrop = () => getMasterComponent().showBackdrop();

export const hideBackdrop = () => getMasterComponent().hideBackdrop();

export const buildHeader = (additionalHeaders = {}) => {
    return {
        ...(getHeaders() && getHeaders().headers),
        ...additionalHeaders
    }
}

export const getHeaders = () => {
    let result = getGuiSession();
    if (!result) return null;

    return JSON.parse(result.headers);
}

export const replaceSpace = (str, replaceWith = '-') => {
    return str.replace(/\s/g, replaceWith);
}

export const isNumber = (input) => {
    var event = input || window.event;
    var keyCode = event.keyCode || event.which;
    var key = String.fromCharCode(keyCode);
    var regex = /^[0-9.,\b]+$/;

    if (key.length == 0) return;

    if(keyCode == 188 || keyCode == 190 || keyCode == 45) return;
    else {
        if (!regex.test(key)) {
            event.returnValue = false;
            if (event.preventDefault) event.preventDefault();
        }
    }
}

export const isValidURL = (url) => {
    try {
        return new URL(url);
    }
    catch {
        return false;
    }
}

export const isValidURL_returnsBoolean = (url) => {
    try {
        return Boolean(new URL(url));
    }
    catch {
        return false;
    }
}

export const getUnixTime = (date) => {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return Math.floor(date.getTime() / 1000);
}

/**
 * Get Random key something sheeeesh >.<
 * 
 * @param { string } feature
 * @param { boolean } useRouterName
 * @returns { string } Unique feature name
 */
export const getRandomKey = () => {
    const shuffle = v=>[...v].sort(_=>Math.random()-.5).join('')
    return shuffle(Math.random().toString(36).substr(2, 5) + Date.now());
}

export const isObject = (object) => {
    return typeof object === 'object' && !Array.isArray(object) && object !== null
}

/**
 * @function
 * HTTP Request util function powered by vm.$http, a vue prototype that leverages axios,
 * and already has configured headers.
 * 
 *  * ```js
 * //Example:
 * const {data, status} = await _FETCH('POST', '/api/users', { foo: 'bar' }, { ...additionalHeaders })
 * ```
 * 
 * @remark Supplied headers are based on current gui.
 * @template T
 * @param { 'GET'|'POST'|'PATCH'|'DELETE' } method - Request method.
 * @param { string } endpoint - API endpoint.
 * @param { { [key: string]: any } } [body] - Request body. Only applied to PATCH and POST request method. Optional.
 * @param { { [key: string]: any, returnDataType: T } } [additionalHeaders] - Additional headers will be merged with the configured headers except for property returnDataType which its type will be used as generic. Optional.
 * @returns { Promise<AxiosResponse<T>> }
 * 
 */
export const _FETCH = async (method, endpoint, body = {}, additionalHeaders = {}) => {
    if (!['get', 'post', 'patch', 'delete'].includes(method.toLocaleLowerCase())) {
        throw Error(`Method ${method} is not supported.`);
    }

    if (!isObject(additionalHeaders)) {
        throw Error(`Additional Headers must be a type of Object`)
    }

    delete additionalHeaders.returnDataType;
    const headers = { ...HEADERS(), ...additionalHeaders }

    method = method.toLocaleLowerCase()

    if (['post', 'patch'].includes(method.toLocaleLowerCase())) {
        return vm.$http[method](endpoint, body, headers)
    }

    return vm.$http[method](endpoint, null, headers)
}

export const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
}

export function isValidHttpUrl(url) {
    let parsedUrl;

    try {
        parsedUrl = new URL(url);
    } catch (_) {
        return false;  
    }

    return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
}

export const getCurrencySymbol = (currency) => {
    const symbols = { 
        AED: 'د.إ', AFN: '؋', ALL: 'L', AMD: '֏', ANG: 'ƒ', AOA: 'Kz', ARS: '$', AUD: '$', AWG: 'ƒ', AZN: '₼', BAM: 'KM', BBD: '$', BDT: '৳', BGN: 'лв', BHD: '.د.ب', 
        BIF: 'FBu', BMD: '$', BND: '$', BOB: '$b', BOV: 'BOV', BRL: 'R$', BSD: '$', BTC: '₿', BTN: 'Nu.', BWP: 'P', BYN: 'Br', BYR: 'Br', BZD: 'BZ$', CAD: '$', CDF: 'FC', 
        CHE: 'CHE', CHF: 'CHF', CHW: 'CHW', CLF: 'CLF', CLP: '$', CNH: '¥', CNY: '¥', COP: '$', COU: 'COU', CRC: '₡', CUC: '$', CUP: '₱', CVE: '$', CZK: 'Kč', DJF: 'Fdj',
        DKK: 'kr', DOP: 'RD$', DZD: 'دج', EEK: 'kr', EGP: '£', ERN: 'Nfk', ETB: 'Br', ETH: 'Ξ', EUR: '€', FJD: '$', FKP: '£', GBP: '£', GEL: '₾', GGP: '£', GHC: '₵', 
        GHS: 'GH₵', GIP: '£', GMD: 'D', GNF: 'FG', GTQ: 'Q', GYD: '$', HKD: '$', HNL: 'L', HRK: 'kn', HTG: 'G', HUF: 'Ft', IDR: 'Rp', ILS: '₪', IMP: '£', INR: '₹', IQD: 'ع.د', 
        IRR: '﷼', ISK: 'kr', JEP: '£', JMD: 'J$', JOD: 'JD', JPY: '¥', KES: 'KSh', KGS: 'лв', KHR: '៛', KMF: 'CF', KPW: '₩', KRW: '₩', KWD: 'KD', KYD: '$', KZT: '₸', LAK: '₭', 
        LBP: '£', LKR: '₨', LRD: '$', LSL: 'M', LTC: 'Ł', LTL: 'Lt', LVL: 'Ls', LYD: 'LD', MAD: 'MAD', MDL: 'lei', MGA: 'Ar', MKD: 'ден', MMK: 'K', MNT: '₮', MOP: 'MOP$', MRO: 'UM', 
        MRU: 'UM', MUR: '₨', MVR: 'Rf', MWK: 'MK', MXN: '$', MXV: 'MXV', MYR: 'RM', MZN: 'MT', NAD: '$', NGN: '₦', NIO: 'C$', NOK: 'kr', NPR: '₨', NZD: '$', OMR: '﷼', PAB: 'B/.', 
        PEN: 'S/.', PGK: 'K', PHP: '₱', PKR: '₨', PLN: 'zł', PYG: 'Gs', QAR: '﷼', RMB: '￥', RON: 'lei', RSD: 'Дин.', RUB: '₽', RWF: 'R₣', SAR: '﷼', SBD: '$', SCR: '₨', SDG: 'ج.س.', 
        SEK: 'kr', SGD: 'S$', SHP: '£', SLL: 'Le', SOS: 'S', SRD: '$', SSP: '£', STD: 'Db', STN: 'Db', SVC: '$', SYP: '£', SZL: 'E', THB: '฿', TJS: 'SM', TMT: 'T', TND: 'د.ت', 
        TOP: 'T$', TRL: '₤', TRY: '₺', TTD: 'TT$', TVD: '$', TWD: 'NT$', TZS: 'TSh', UAH: '₴', UGX: 'USh', USD: '$', UYI: 'UYI', UYU: '$U', UYW: 'UYW', UZS: 'лв', VEF: 'Bs', 
        VES: 'Bs.S', VND: '₫', VUV: 'VT', WST: 'WS$', XAF: 'FCFA', XBT: 'Ƀ', XCD: '$', XOF: 'CFA', XPF: '₣', XSU: 'Sucre', XUA: 'XUA', YER: '﷼', ZAR: 'R', ZMW: 'ZK', ZWD: 'Z$', ZWL: '$' 
    }

    return ![null, undefined].includes(symbols[currency]) ? symbols[currency] : '';
}

/**
 * Check if feature is in restricted features.
 * @param { string } feature 
 * @returns 
 */
export const isFeatureRestricted = (feature = '') => {
    feature = feature.replace(/ /g, '-').toLocaleLowerCase();

    if (useFeatureAccessStore().restrictedFeatures.includes('*')) {
        return true;
    }

    return useFeatureAccessStore()
        .restrictedFeatures
        .includes(feature);
};

/**
 * Check if feature is not in restricted features for current user.
 * @param { string } feature
 * @returns
 */
export const isFeatureAllowed = (feature) => {
    return !isFeatureRestricted(feature);
};

/**
 * Compare Array
 * @param {a: JSON.stringify | String, b: JSON.stringify | String, method: 'string' | 'stringify'}
 * @returns
 * 
*/
export const compareArrays = (a, b, method = 'string') => {
    if(method.toLowerCase() == 'stringify') {
        return JSON.stringify(a) === JSON.stringify(b)
    } else {
        return a.toString() === b.toString()
    }
}

export const getRole = (access_level) => {
    const access_level_options = [
        { label: "Researcher", value: 1 },
        { label: "QA", value: 2 },
        { label: "Lawyer", value: 3 },
        { label: "Administrator", value: 4 },
        { label: "Campaign Lead", value: 5 },
        { label: "Client", value: 6 },
        { label: "Super Admin", value: 7 }
    ]

    if (access_level === undefined) {
        return null;
    }
    return access_level_options[access_level - 1].label;
}
