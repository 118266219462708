import DefaultFund from '@/services/defaultFund';
import SettlementFund from '@/services/settlementFund';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: {}
  },
  getters: {
    byCaseID: (state) => (id) => {
        return state.all[id];
    }
  },
  actions: {
    distribute({ commit }, payload) {
      commit('distribute', payload);
    }
  },
  mutations: {
    add(state, _case) {
      let defaultFund = new DefaultFund(_case);
      let settlementFund = new SettlementFund(_case);
      Vue.set(state.all, _case.id, {
        [defaultFund.getType()]: defaultFund,
        [settlementFund.getType()]: settlementFund,
        totalDistributionOccurrence: _case.totalDistributionOccurrence
      });
    },
    distribute(state, { caseID, entities }) {
      for (let entity of entities) {
        state.all[caseID].default.distribute(entity.distributedDefault, entity);
        state.all[caseID].settlement.distribute(entity.distributedSettlement, entity);
      }
      
      state.all[caseID].totalDistributionOccurrence++;
    },
    reset(state) {
      state.all = {};
    }
  },
};
