import Fund from './fund';

export default class DefaultFund extends Fund {
  constructor(_case) {
    super({
      caseID: _case.id,
      totalCreditAmount: _case.totalCreditDefaultAmount,
      totalExpensesAmount: _case.totalExpensesDefaultAmount,
      currentExpensesAmount: 0,
      totalDistributedAmount : _case.totalDistributedDefaultAmount,
      entities: _case.entities.map(e => ({id: e.id, totalDistributedAmount: e.totalDistributedDefaultAmount}))
    });
  }

  getType() {
    return "default";
  }
}
