import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getGuiSession } from '@/utils/session';

export const useSubStatusesStore = defineStore('subStatusesStore', () => {
  const showListingsWithSubstatuses = ref(true);
  const subStatuses = ref({})
  const sample = ref([
    { label: "Sub Status 1", id: 1, count: 0 },
    { label: "Sub Status 2", id: 2, count: 100 },
    { label: "Sub Status 3", id: 3, count: 20 },
    { label: "Sub Status 4", id: 4, count: 50 },
    { label: "Sub Status 5", id: 5, count: 220 },
    { label: "Sub Status 6", id: 6, count: 1230 }
  ])
  const resetCurrentSelectedSubStatus = () => {
    currentSelectedSubStatus.value = null;
  }
  const currentSelectedSubStatus = ref(null);
  const currentSelectedSubStatusLabel = ref(null);
  const canSeeSubStatuses = ![3, 6].includes(getGuiSession().access_level)

  return {
    showListingsWithSubstatuses,
    subStatuses,
    sample,
    resetCurrentSelectedSubStatus,
    canSeeSubStatuses,
    currentSelectedSubStatusLabel,
  };
});
