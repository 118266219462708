/** @typedef { import('vue-router').Route } Route */

import { isFeatureRestricted } from './common';
import { useFeatureAccessStore } from '../store/pinia/feature-access';
import { ListingStatus } from './constants';

export const useRouteRestriction = async () => {
  /** @type { Route } */
  // @ts-ignore
  const route = vm.$route;

  if (route.name === null) {
    return;
  }

  /** @type { 'verification' | 'admin' | 'lawfirm' } */
  // @ts-ignore
  const gui = route.path.split('/').at(1);

  if (await isGuiRestricted(gui)) {
    goToHoldUpPage();
    return;
  }

  const restrictableRoutes = gui === 'verification' ? verificationRestrictableRoutes : ( gui === 'admin' ? adminRestrictableRoutes : lawfirmRestrictableRoutes);

  const restrictableRoute = restrictableRoutes
    .find(restrictableRoute => restrictableRoute.name === route.name);

  if (restrictableRoute === undefined) {
     return;
  }

  const { features, parentFeature } = restrictableRoute;

  for (const feature of features) {
    const isParentFeatureRestricted = isFeatureRestricted(parentFeature);

    if (isParentFeatureRestricted) {
      goToHoldUpPage();
      break;
    }

    const { classname, query, params } = feature;
    const isClassnameRestricted = isFeatureRestricted(classname);

    if (!isClassnameRestricted) {
      continue;
    }

    if (query !== undefined) {
      const isRestricted = useQueryRestriction(query, route);

      if (!isRestricted) {
        continue;
      }

      break;
    }

    if (params !== undefined ) {
      const isRestricted = useParamRestriction(params, route);

      if (!isRestricted) {
        continue;
      }

      break;
    }

    goToHoldUpPage();
    break;
  }
}

/**
 * @param { 'verification' | 'admin' | 'lawfirm' } gui 
 * @returns 
 */
const isGuiRestricted = async (gui) => {
  await useFeatureAccessStore().isFeatureRestrictionsReady;
  const { restrictedFeatures } = useFeatureAccessStore();

  const isDefaultRestriction = restrictedFeatures.includes('*');

  if (isDefaultRestriction) {
    return false;
  }

  if (gui === 'verification') {
    return isFeatureRestricted('v-login-access');
  }

  if (gui === 'admin') {
    return isFeatureRestricted('a-login-access');
  }

  return isFeatureRestricted('cm-login-access');
}

/**
 * 
 * @param { {[key: string]: string} } query 
 * @param { Route } route 
 * @returns 
 */
const useQueryRestriction = (query, route) => {
  const hasSimilarQuery = Object.keys(query ?? {}).some(key => isSameString(query[key], ''+route.query[key]));

  if (!hasSimilarQuery) {
    return false;
  }

  if (route.name !== 'listing') {
    goToHoldUpPage();
    return true;
  }

  const listingRoute = verificationRestrictableRoutes.find(item => item.name === 'listing');
  const hasRestrictedFeature = listingRoute.features.some(item => isFeatureRestricted(item.classname));

  if (!hasRestrictedFeature) {
    return false;
  }

  if (query.status === 'Work in Progress') {
    const { status } = listingRoute.features.find(item => !isFeatureRestricted(item.classname))?.query

    if (status === undefined) {
      return false;
    }

    // @ts-ignore
    vm.$router.push({...vm.$route, query: { ...vm.$route.query, status }});
    return true;
  }

  goToHoldUpPage();
  return true;
}

/**
 * 
 * @param { { [key: string]: string } } params 
 * @param { Route } route 
 * @returns 
 */
const useParamRestriction = (params, route) => {

  /** 
   * Use to redirect users to holdup page when accessing case page and cm-button-manage-case is applied.
   **/
  if (params.isInsideCase && +route.params.case_id !== 0) {
    goToHoldUpPage();
    return true;
  }

  const isSameParams = Object.keys(params ?? {}).every(key => isSameString(params[key], route.params[key]));

  if (!isSameParams) {
    return false;
  }

  if (params.status === 'For Review') {
    const lawfirmListingFeatures = lawfirmRestrictableRoutes.find(item => item.name === 'lawfirmlisting');
    const { status } = lawfirmListingFeatures.features.find(item => !isFeatureRestricted(item.classname))?.params;

    if (status === undefined) {
      return false;
    }

    // @ts-ignore
    vm.$router.push({ ...vm.$route, params: { ...vm.$route.params, status }});
    return true;
  }

  goToHoldUpPage();
  return true;
}

/** @type { RestrictableRoute[] } */
const verificationRestrictableRoutes = [
  { name: 'newlisting',           features: [{ classname: 'v-link-manual-addition'}] },
  { name: 'manualaddition',       features: [{ classname: 'v-link-manual-addition'}] },
  { name: 'auto-capture-listing', features: [{ classname: 'v-link-auto-capture'}]},
  { name: 'listing', features: [
    { classname: 'v-filter-client-listing',   query: { status: 'Client Listing' }},
    { classname: 'v-filter-work-in-progress', query: { status: 'Work in Progress' }},
    { classname: 'v-filter-researched',       query: { status: 'Researched' }},
    { classname: 'v-filter-qualified',        query: { status: 'Qualified' }},
    { classname: 'v-filter-monitoring',       query: { status: 'Monitoring' }},
    { classname: 'v-filter-accepted',         query: { status: 'Accepted' }},
    { classname: 'v-filter-filed',            query: { status: 'Filed' }},
    { classname: 'v-filter-invalid',          query: { status: 'Invalid' }},
    { classname: 'v-filter-has-potential',    query: { status: 'Has Potential' }},
    { classname: 'v-filter-auto-qualified',   query: { status: 'Hacked' }},
    { classname: 'v-filter-rejected',         query: { status: 'Rejected' }},
    { classname: 'v-filter-submitted',        query: { status: 'Submitted' }},
    { classname: 'v-filter-archived',         query: { status: 'Archived' }},
    { classname: 'v-filter-recycled',         query: { status: 'Recycled' }}
  ]},
  { name: 'listinginfo', features: [
    { classname: 'v-link-edit-listing' },
    { classname: 'v-link-edit-listing-wip',            query: { qflag_id: ''+ListingStatus.WIP        } },
    { classname: 'v-link-edit-listing-auto-qualified', query: { qflag_id: ''+ListingStatus.Hacked     } },
    { classname: 'v-link-edit-listing-researched',     query: { qflag_id: ''+ListingStatus.Researched } },
    { classname: 'v-link-edit-listing-qualified',      query: { qflag_id: ''+ListingStatus.Qualified  } },
    { classname: 'v-link-edit-listing-invalid',        query: { qflag_id: ''+ListingStatus.Invalid    } },
    { classname: 'v-link-edit-listing-monitoring',     query: { qflag_id: ''+ListingStatus.Monitoring } },
    { classname: 'v-link-edit-listing-rejected',       query: { qflag_id: ''+ListingStatus.Rejected   } },
    { classname: 'v-link-edit-listing-accepted',       query: { qflag_id: ''+ListingStatus.Accepted   } },
    { classname: 'v-link-edit-listing-submitted',      query: { qflag_id: ''+ListingStatus.Submitted  } },
    { classname: 'v-link-edit-listing-filed',          query: { qflag_id: ''+ListingStatus.Filed      } },
    { classname: 'v-link-edit-listing-archived',       query: { qflag_id: ''+ListingStatus.Archived   } },
    { classname: 'v-link-edit-listing-recycled',       query: { qflag_id: ''+ListingStatus.Recycled   } },
  ]},
];

/** @type { RestrictableRoute[] } */
const lawfirmRestrictableRoutes = [
  // { name: 'lawfirmexport',                    features: [{ classname: 'cm-button-export-vendor-data'}] },
  { name: 'lawfirmcounterfeitchecker',        features: [{ classname: 'cm-link-data-checker'}] },
  { name: 'lawfirmcampaigncounterfeitchecker',features: [{ classname: 'cm-link-data-checker'}] },
  { name: 'lawfirmsubmissionspackmonitoring', features: [{ classname: 'cm-link-status-monitoring-page'}] },
  { name: 'lawfirmnewcase',                   features: [{ classname: 'cm-link-create-case' }, { classname: 'cm-link-edit-case' }] },
  { name: 'lawfirmeditcaselisting',           features: [{ classname: 'cm-link-edit-listing' }] },
  { name: 'lawfirmeditlisting',           features: [{ classname: 'cm-link-edit-listing' }] },
  { name: 'lawfirmlisting', features: [
    { classname: 'cm-filter-has-potential',            params: { status: 'Has Potential'}},
    { classname: 'cm-filter-for-review',               params: { status: 'For Review'}},
    { classname: 'cm-filter-monitoring',               params: { status: 'Monitoring'}},
    { classname: 'cm-filter-initial-review-completed', params: { status: 'Initial Review Completed'}},
    { classname: 'cm-filter-accepted',                 params: { status: 'Accepted'} },
    { classname: 'cm-filter-submitted',                params: { status: 'Submitted'}},
    { classname: 'cm-filter-rejected',                 params: { status: 'Rejected'}},
    { classname: 'cm-filter-for-internal-review',      params: { status: 'For Internal Review'}},
    { classname: 'cm-filter-filed',                    params: { status: 'Filed'}},
    { classname: 'cm-filter-cases',                    params: { status: 'Cases', case_id: '0' }},
    { classname: 'cm-button-manage-case',              params: { status: 'Cases', isInsideCase: true /* case_id === 1 means inside a case. */ }}, 
  ]},
  { name: 'lawfirmcasefilesexplorer', features: [{ classname: 'cm-button-case-files-explorer'}] }
];

/** @type { RestrictableRoute[] } */
const adminRestrictableRoutes = [
  { name: 'adminpaymentreports',    parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-payment-accounts-reports' }] },
  { name: 'adminresearchersoutput', parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-researchers-output' }] },
  { name: 'adminweeklyreport',      parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-weekly-reports-assignment' }] },
  { name: 'adminpaymentreports',    parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-payment-accounts-reports' }] },
  { name: 'admindatacheckerreport', parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-data-checker' }] },
  { name: 'admin3monthreport',      parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-data-analysis-reports' }] },
  { name: 'admininfographicreport', parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-campaign-infographic' }] },
  { name: 'admincampaignreport',    parentFeature: 'a-link-reports',  features: [{ classname: 'a-link-reports-campaign-reports' }] },

  { name: 'adminenvaccessmanagement',    parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-environment-access-management' }] },
  { name: 'admindummies',                parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-dummy-accounts' }] },
  { name: 'admintdatapriority',          parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-data-priority' }]},
  { name: 'admininfringementindicators', parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-infringement-indicators' }] },
  { name: 'adminpaymentmethods',         parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-payment-methods' }] },
  { name: 'adminplatforms',              parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-platforms' }] },
  { name: 'adminreasons',                parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-predefined-reasons' }] },
  { name: 'adminshippinglocations',      parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-shipping-locations' }] },
  { name: 'adminterritory',              parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-territory' }] },
  { name: 'adminitemcampaigns',          parentFeature: 'a-link-settings', features: [{ classname: 'a-link-settings-classes-and-item-types' }] },

  { name: 'adminsellers',    parentFeature: 'a-link-sellers', features: [{ classname: 'a-link-sellers-manage-sellers' }] },
  { name: 'adminwhitelists', parentFeature: 'a-link-sellers', features: [{ classname: 'a-link-sellers-manage-white-list' }] },

  { name: 'adminartemisusers', parentFeature: 'a-link-users-management', features: [{ classname: 'a-link-users-management-artemis-users' }] },
  { name: 'adminteams',        parentFeature: 'a-link-users-management', features: [{ classname: 'a-link-users-management-artemis-teams' }] },

  { name: 'adminaccountingreports',      features: [{ classname: 'a-link-accounting-reports'}] },
  { name: 'admincampaignaccounting',     features: [{ classname: 'a-link-accounting-reports'}, { classname: 'a-link-redirect-to-campaign-accounting-report'}] },
  { name: 'admincampaigns',              features: [{ classname: 'a-link-campaigns'}] },
  { name: 'adminlawfirms',               features: [{ classname: 'a-link-lawfirm'}] },
  { name: 'admineditcampaign',           features: [{ classname: 'a-link-edit-campaign'}] },
  { name: 'admineditlawfirm',            features: [{ classname: 'a-link-edit-lawfirm'}] },
  { name: 'adminrightsholder',           features: [{ classname: 'a-link-rightsholders'}] },
  { name: 'adminFeatures',               features: [{ classname: 'a-link-feature-access'}] },
  { name: 'adminUserRestrictions',       features: [{ classname: 'a-link-feature-access'}] },
  { name: 'adminRoleBasedRestrictions',  features: [{ classname: 'a-link-feature-access'}] },
  { name: 'adminautocampaignassignment', features: [{ classname: 'a-link-auto-assignment'}] }
];

/**
 * Lowercase and removes spaces of string.
 * 
 * @param { string } string 
 * @returns 
 */
const simplify = (string) => ''+string.toLocaleLowerCase().replace(/\s/g, '');

/**
 * 
 * @param { string } a 
 * @param { string } b 
 * @returns 
 */
const isSameString = (a, b) => simplify(a) === simplify(b);

const goToHoldUpPage = () => {
  /** @type { Route } */
  // @ts-ignore
  const route = vm.$route;
  const gui = route.path.split('/')[1];

  // @ts-ignore
  vm.$router.push({ path: `/${gui}/holdup`});
}

/**
 * @typedef { object } RestrictableRoute
 * @property { string } name
 * @property { string } [parentFeature]
 * @property { Array<{ classname: string; query?: {[key: string]: string }, params?: { [key: string]: string }}> } features
 */



