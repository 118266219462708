import store from '@/store';
import Distribution from './distribution';

export class SourceOfFundFactory {
  static getList() {
    return [{
      label: 'Gross Settlement',
      value: 1,
      gross: true,
      getFund: (caseID) => {
        return store.getters['fund/byCaseID'](caseID).settlement;
      }
    }, {
      label: 'Net Settlement',
      value: 2,
      gross: false,
      getFund: (caseID) => {
        return store.getters['fund/byCaseID'](caseID).settlement;
      }
    }, {
      label: 'Default',
      value: 3,
      gross: false,
      getFund: (caseID) => {
        return store.getters['fund/byCaseID'](caseID).default;
      }
    }];
  }

  static getInstance(type) {
    return this.getList().find(f => f.value === type);
  }
}

export default class Fund extends Distribution {
  #totalCreditAmount;
  #totalExpensesAmount;
  #currentExpensesAmount;
  #caseID;

  constructor({
    caseID,
    totalCreditAmount,
    totalExpensesAmount,
    totalDistributedAmount,
    currentExpensesAmount,
    entities,
  }) {
    super({totalDistributedAmount, entities});
    this.#caseID = caseID;
    this.#totalCreditAmount = totalCreditAmount;
    this.#totalExpensesAmount = totalExpensesAmount;
    this.#currentExpensesAmount = currentExpensesAmount;
  }

  get caseID() {
    return this.#caseID;
  }

  get totalCreditAmount() {
    return this.#totalCreditAmount;
  }

  get currentExpensesAmount() {
    return this.#currentExpensesAmount;
  }

  get gross() {
    return (this.#totalCreditAmount - this.#totalExpensesAmount - this.totalDistributedAmount) + this.#currentExpensesAmount;
  }

  get net() {
    return this.#totalCreditAmount - this.#totalExpensesAmount - this.totalDistributedAmount;
  }

  get totalExpensesAmount() {
    return this.#totalExpensesAmount;
  }

  resetCurrentExpensesAmount() {
    this.#currentExpensesAmount = 0;
  }

  getType() {
      throw new Error("type is not implemented");
  }
}
