import config from '../../../public/static/theme/conf.json'

export default {
  initializeThemeAudio({ commit, getters, state, dispatch }) {
      if (getters.isHolidayTheme) {
          commit('INIT_THEME_AUDIO', document.getElementById('themeAudio'))

          if (state.themeAudio && typeof state.themeAudio != 'undefined') {
            state.themeAudio.volume = config.audio.volume;
          }

          config.audio?.additionalAudio?.forEach((audio, index) => {
              commit('INIT_THEME_ADDITIONAL_AUDIO', document.getElementById(`additionalAudio--${index}`))

              if (state.themeAdditionalAudio.length && typeof audio != 'undefined') {
                state.themeAdditionalAudio[index].volume = audio.volume;
              }
          })
      }
  },
  stopThemeAudio({ getters, state, dispatch }) {
    if (getters.isHolidayTheme) {
      if (state.themeAudio && typeof state.themeAudio != 'undefined') {
        state.themeAudio.pause()
      }

      config.audio?.additionalAudio?.forEach((audio, index) => {
        if (state.themeAdditionalAudio.length && typeof state.themeAdditionalAudio[index] != 'undefined') {
          state.themeAdditionalAudio[index].pause()
        }
      })
    }
  },
  getThemePathname({ commit }) {
    commit('GET_PATH_NAME', location.pathname)
  }
}