import { useFeatureAccessStore } from '../store/pinia/feature-access';

const featureAccessStore = useFeatureAccessStore;

/**
 * Class name of feature.
 * @param { string } feature 
 * @returns 
 */
const isRestricted = (feature) => {
  const { restrictedFeatures } = featureAccessStore();

  if (restrictedFeatures.includes('*')) {
    return true;
  }

  return restrictedFeatures.includes(feature);
};


/**
 * Class name of feature.
 * @param { string } feature 
 * @returns 
 */
const isAllowed = (feature) => {
  return !isRestricted(feature);
}

export const featureAccessPrototype = Object.freeze({
  isRestricted, 
  isAllowed,
  restrictions : () => featureAccessStore().restrictedFeatures,
  features     : () => featureAccessStore().features,
  isReady: () => featureAccessStore().isFeatureRestrictionsReady
});