import { Model } from '@vuex-orm/core';
import AsyncRequest from "@/api/AsyncRequest";

export default class AsyncModel extends Model {
    static state() {
        return {
            loading: false,
            initialized: false,
            retrieving: false
        }
    }

    static api() {
        return new AsyncRequest(this);
    }
}