/*
|--------------------------------------------------------------------------
| Exportable String Utility functions
|--------------------------------------------------------------------------
| Here is where you can create functions that you think can be used often.
| To reduce code redundancy. Also, please provide comments that describe 
| the functions. See jsdoc.app for documentation.
|
*/

/**
 * Capitalize the first letter of each word in a string
 * ```js
 * //Example
 * capitalize('john doe') //'John Doe'
 * ```
 * @param {string} string 
 * @returns {string}
 */
export const capitalize = string => {
    return string.split(/\s+/).map(value => ucFirst(value)).join(' ')
}

/**
 * Capitalize the first letter of a string
 * ```js
 * //Example
 * ucFirst('john doe') //'John doe'
 * ```
 * @param {string} string Input string
 * @returns {string}
 */
 export const ucFirst = string => {
    if(typeof string !== 'string')
        throw new Error('Given value is not a string')

    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const base64encode = (str) => {
    return window.btoa(unescape(encodeURIComponent(str)))
}

export const trimSlash = (str) => str?.replace(/^\/|\/$/g, '');

export const filterNonNull = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value));
}

export const ucWords = (string, delimiter = ' ') => {
    if (string == undefined || string == "")
        return string

    var words = string.trim().split(delimiter)
    var result = []

    if (words.length > 1) {
        for(var i = 0; i < words.length; i++) {
            result.push(ucFirst(words[i]))
        }

        return result.join(' ')
    }
    
    return ucFirst(words.join(''))
}

/**
 * 
 * @param {string} str ex: xtzk.en.alibaba.com
 * @param {string} rule ex: *.alibaba.com
 * @returns {boolean}
 */
export function compareStr(str, rule) {
    let escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
}