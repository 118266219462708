import Vuex from 'vuex'
import Vue from 'vue'
import actions from './actions'
import mutations from './mutations'
import axios from '../axiosMime'
import baseAxios from 'axios'
import verification from './verification/index'
import holidayTheme from './holiday_theme/index'
import config from '../../public/static/theme/conf.json'
import counterfeitchecker from './cm/counterfeitchecker/index'
import _case from './modules/case'
import accountingInfo from './modules/accountingInfo'
import entity from './modules/entity'
import fund from './modules/fund'
// import featureAccessManagement from './modules/feature-access-management'
import moment from 'moment'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import preFilingGroup from '@/models/preFilingGroup'
import Platform from '@/models/platform'
import PlatformMatchingPattern from '@/models/platformMatchingPattern'
import { createAuthenticatedAxios, addDefaultParamsSerializer } from "@/utils/axios";
import Campaign from '@/models/campaign'
import ClientListing from '@/models/clientListing'

Vue.use(Vuex)
VuexORM.use(
    VuexORMAxios,
    {
        axios: addDefaultParamsSerializer(
            createAuthenticatedAxios(process.env.VUE_APP_URL)
        )
    }
)
const database = new VuexORM.Database()
database.register(preFilingGroup)
database.register(Platform)
database.register(PlatformMatchingPattern)
database.register(Campaign);
database.register(ClientListing);

export default new Vuex.Store({
    state: {
        username         : "",
        show_navbar      : false,
        show_sidebar     : false,
        show_filterdrawer: false,
        grey_bg          : false,
        navbar_type      : "main",
        manually_added   : false,
        seller_id        : "",
        listing_id       : "",
        campaigns        : [],
        config           : {headers: {"Content-Type": "application/json","Authorization": "Bearer "+localStorage.getItem("token"),"Gui": "ver"}},
        filters          : {
            status               : "",
            selected_seller_flags: [],
            platforms            : []
        },
        show_listinginfo    : false,//showing listinginfo fields during manual addition
        show_evidence       : false,//showing evidence fields during manual addition,
        exit_manual_addition: false,
        active_listing_info : {},
        is_active_list_ready: false,
        is_researchable     : false,
        allow_exit          : false,
        is_disable          : true,
        shown_data          : [],
        show_error          : false,
        notifications       : [],
        page_notification   : 1,
        external_notification_listener : null,
        curr_adm_fd_status : 0, //Use to store the current selected status in admin filter drawer,
        manual_addition_errors :[],
        feature_access: 0,
        listing_reference: {},
        seller_reference: {},
        view_key: 0,
        sidebar_campaigns: [],
        cancellationTokenSource: axios.CancelToken.source(),
        leadNotifications: [],
        ...verification,
        ...holidayTheme,
        ...counterfeitchecker,
        hasUploadedCaseFile: false,
        hasDeletedCaseFile: false,
        // filed_listings: [],
        hasUsed_globalSearch: false,
        globalSearch_input: '',
        globalSearch_count: {},
        globalSearch_listings: [],
        previous_route: {},
        keywords: '',
        hasReassign_clientListings: false,
        show_clientListings: false,
        Listing_has_clientListings: false,
        client_Listings: [],
        client_Listings_manualAddition: [],
        addNewGroupListing: false,
        currentForValidation: {},
        // trigger for Checking for Client Listings
        hasTriggeredSomething: false,
        //trigger for Researched Tracker for Researchers
        showResearchedTracker: false,
        researchTrackerLoad: true,
        currentAssignedCampaign: [],
        waitingTracker: false,
        relatedCampaignDuplicates: {},
        relatedCampaignBypass: {
            allow: false,
            continueFunction: () => {}
        },
        lawfirmCoverPage: 0,
        paymentAccountsToBeAdded: [],
        weekly_quota: {
            current: 0,
            target: 0,
            info: {}
        },
        user_profile_vuex: null,
        /**
         * cm.vue && Listings.vue
         * Triggered when normal search is used
         * @var {
         *  data: [],
         *  response_status: 'success' - 200 | !200 - 'failed',
         * }
         */
        case_seller_accounts_vuex: {},
        lf_platforms_vuex2: null,
    },
    getters:{
        isHolidayTheme: () => config.theme.show && !location.pathname.includes('/lawfirm'),
        themeListingPreview: () => config.theme.show && !location.pathname.includes('/lawfirm') && config.theme.hasListingDefaultPreview ? `/static/theme/listing-default-preview.svg` : `/static/svg/Listing Default Photo.svg`,
        getUnreadNotificationCount: (state) => {
            let count = state.notifications.filter(n => n.read_at === null).length;
            return count > 99 ? "99+" : count;
        },
        getPaymentAccountsToBeAdded: (state) => state.paymentAccountsToBeAdded, 
        getRelatedCampaignBypass: (state) => state.relatedCampaignBypass.allow,
        checkFeatureAccess({commit, state}, access) {
            let allow = false
            let gui = location.pathname.includes('/lawfirm') ? 'lawfirm' : 'admin'
            let access_level = ((JSON.parse(localStorage.getItem(gui)) || {}).access_level || 0)
            const isCM = gui == 'lawfirm'

            if (isCM && [7].includes(access_level))
                return true

            if (isCM && [6, 3].includes(access_level) && access.includes(1))
                return true

            if (state.feature_access == 0)
                return allow

            if (access.some(a => state.feature_access.includes(a)))
                allow = true
            
            return allow
        },
        getCcCampaigns: (state) => {
            return state.ccCampaigns
        }
    },
    // Originally:
    actions,
    mutations,
    modules: {
        'case': _case,
        accountingInfo,
        entity,
        fund,
        //TODO: Disabled for now and will be replaced with Pinia store.
        // featureAccessManagement
    },
    plugins: [VuexORM.install(database)]
})
