import axios from "@/AxiosIndex";

/* const listings = */ 
export default {
    checkReason({ commit }, reason) {
        commit('CHECK_REASON', reason)
    },
    checkSubReason({ commit }, subreason) {
        commit('CHECK_SUBREASON', subreason)
    },
    checkOtherReason({ commit }, other) {
        commit('CHECK_OTHER_REASON', other)
    },
    inputOtherReasonComment({ commit }, comment) {
        commit('INPUT_OTHER_REASON_COMMENT', comment.trim())
    },
    checkCommission({ commit }, commission) {
        commit('CHECK_COMMISSION', commission)
    },
    resetPredefinedReasonState({ commit }) {
        commit('RESET_PREDEFINED_REASON_STATE')
    },
    setNoSellerAttached({commit}, bool) {
        commit('SET_NO_SELLER_ATTACHED', bool)
    },
    setExistingSeller({commit}, id) {
        commit('SET_EXISTING_SELLER', id)
    },
    setPotentialWhitelists({commit}, whitelists) {
        commit('SET_POTENTIAL_WHITELISTS', whitelists)
    },
    /**
     * Get the potential whitelists by providing name and campaign.
     * See deliverable: {@link https://podio.com/apmcllccom/artemis/apps/deliverables/items/227}
     *
     * @param {Array<{name: string, url: URL}>} sellers
     * @return {Promise<boolean|*>}
     */
    async checkPotentialWhitelists({_}, sellers) {
        const gui           = vm.$route.path.includes('lawfirm') ? 'lawfirm': 'ver'
        const headers       = vm.$session.get(`${gui}_headers`)
        const {campaign_id} = vm.$route.params

        return vm.$http.post('/api/potential_whitelists', {sellers, campaign_id}, headers)
            .then(res => res.data)
            .catch(({response}) => response.data)
    },
    async acknowledgeWhitelist({_}, acknowledgement) {
        const gui     = vm.$route.path.includes('lawfirm') ? 'lawfirm': 'ver'
        const headers = vm.$session.get(`${gui}_headers`)
        
        return vm.$http.post('/api/acknowledgements', acknowledgement, headers)
            .then(res => res.data)
            .catch(({response}) => response.data)
    },
    /**
     * Update any key of state dynamically.
     * state should be a format of {nameOfState: value}
     * ```js
     * const state = {dateOfCaptured: '08/18/2021'}
     * ```
     */
    updateState({commit}, state) {
        commit('UPDATE_STATE', state)
    }
}

/* //const newActionsHere = {}
export default {
    ...listings,
    //...newActionsHere
} */