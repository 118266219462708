export const getGuiSession = () => {
    let result = null;
    if (location.pathname.includes('/verification')) {
        result = JSON.parse(localStorage.getItem('ver'));
    }
    else if (location.pathname.includes('/lawfirm')) {
        result = JSON.parse(localStorage.getItem('lawfirm'));
    }
    else if (location.pathname.includes('/admin')) {
        result = JSON.parse(localStorage.getItem('admin'));
    }

    return result;
}

export const getToken = () => {
    return getGuiSession() && getGuiSession().token;
}

export const getAccessLevel = () => {
    return getGuiSession() && getGuiSession().access_level;
}

export const GUI = location.href.includes('/lawfirm/') ? 'lawfirm' : ( location.href.includes('/verification/') ? 'ver' : 'admin' );

export const getGui = () => GUI;

/**
 * @returns { User & { headers: Header } }
 */
export const CURRENT_USER_INFO = () => JSON.parse(localStorage.getItem(GUI) ?? "{}")

/**
 * @type { Header }
 */
export const HEADERS = () => JSON.parse(CURRENT_USER_INFO().headers ?? "{}")
