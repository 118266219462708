import Vue from 'vue';
import store from '@/store'
import { SourceOfFundFactory } from './fund';

/*eslint no-dupe-class-members: "off"*/
export default class Distribution {

  constructor({totalDistributedAmount, entities}) {
    this.totalDistributedAmount = totalDistributedAmount;
    this.entities = {};
    for (const entity of entities) {
      Vue.set(this.entities, entity.id, entity.totalDistributedAmount); //to solve reactivity issue
    }
  }

  getTotalDistributedAmountByEntity(entity) {
    return this.entities[entity.id] || 0;
  }

  getDeductionAmount(amount, slug) {
    let entity = store.getters['entity/byCaseID'](this.caseID).find(e => e.slug === slug);
    let fund = entity
                && entity.sourceOfFunds
                    .map(f => SourceOfFundFactory.getInstance(f))
                    .find(f => f.getFund(this.caseID).getType() === this.getType())
    if (fund) {
      return Math.max(this.calculateAndGetDistributionAmount(amount, fund.gross, entity), 0);
    }

    return 0;
  }

	calculateAndGetDistributionAmount(amount, gross, entity) {
    let amountToDistribute = gross ? amount + this.currentExpensesAmount : amount;
    let distributedAmount = amountToDistribute * (entity.share / 100);
    for (let slug of entity.getDeductionsSlug()) {
      distributedAmount -= this.getDeductionAmount(amount, slug);
    }

    return distributedAmount;
  }

  distribute(amount, entity) {
    if (this.entities[entity.id] === undefined) {
      Vue.set(this.entities, entity.id, 0); //to solve reactivity issue
    }

    this.entities[entity.id] = this.entities[entity.id] + amount;
    this.totalDistributedAmount += amount;
  }
}
