import verification from '@/routes/verification'
import lawfirm from '@/routes/lawfirm'
import admin from '@/routes/admin'

const routes = [
    ...verification,
    ...lawfirm,
    ...admin,
    {
        path:"/",
        redirect:'/lawfirm'
    },
    {
        path     : "/maintenance",
        name     : "maintenance",
        component: () => import('@/components/layouts/Maintenance'),
        meta     : {title: "Artemis | Maintenance"}
    },
    /*{//For Verification //ok
        path: "/verification",
        name: 'verification',
        component:lazyload("verification/auth/VerLogin"),
        meta:{title:"Artemis | Verification"}
    },
    {
        path:"/verification/my/tasks", //ok
        name:"task",
        component:lazyload("verification/Task"),
        meta:{title:"Verification | Tasks"}
    },
    {
        path:"/verification/my/listings/campaign/:campaign_id", //ok
        name:"listing",
        component:lazyload("verification/Listing"),
        meta:{title:"Verification | Listing"},
        props:true
    },
    {
        path:"/verification/newlisting", //ok
        name:"newlisting",
        component:lazyload("verification/NewListing"),
        meta:{title:"Verification | New Listing"}
    },
    {
        path:"/verification/listinginfo/campaign/:campaign_id", //ok
        name:"listinginfo",
        component:lazyload("verification/ListingInfo"),
        meta:{title:"Verification | Edit Listing"},
        props:true
    },
    {
        path:"/verification/manualaddition/campaign/:campaign_id", //ok
        name: "manualaddition",
        component:lazyload("verification/ManualAddition"),
        meta: {title: "Verification | Manual Addition "},
        props: true
    },
    {
        path:"/verification/my/campaigns", //ok
        name:"campaigns",
        component:lazyload("verification/Campaign"),
        meta:{title:"Verification | Campaign"}
    },
    {
        path: "/verification/search", //ok
        name: "search",
        component:lazyload("verification/Search"),
        meta: {title:"Verification | Search Listings"}
    },
    {
        path: "/verification/exportExcel/:campaign_id", //ok
        name:"export",
        component:lazyload("verification/Download"),
        meta: {title:"Verification | Export Excel"}
    },
    {
        path: "/verification/notifications", //ok
        name:"notifications",
        component:lazyload("verification/Notifications"),
        meta: {title:"Verification | Notifications"}
    },*/
    //End of Verification

    /*{//For Lawfirm
        path:"/lawfirm", //ok
        name:"lawfirm",
        // component:LawfirmLogin,
        component:lazyload("lawfirm/auth/LawfirmLogin"),
        meta:{title:"Artemis | Case Management"}
    },
    {
        path:"/lawfirm/listings/review", //ok
        name:'listingreview',
        // component:ListingsReview,
        component:lazyload("lawfirm/ListingsReview"),
        meta:{title:"Case Management | Listing Review"}
    },
    {
        path:"/lawfirm/campaigns", //ok
        name:'lawfirmcampaigns',
        // component:Campaigns,
        component:Campaigns,
        meta:{title:"Case Management | Campaigns"}
    },
    {
        path:"/lawfirm/listings/campaign/:campaign_id/:status/:case_id", //ok
        name:"lawfirmlisting",
        // component:Listings,
        component:lazyload("lawfirm/Listings"),
        meta:{title:"Case Management | My Listings"},
        props:true
    },
    {
        path:"/lawfirm/campaign/:campaign_id/case/:action/:case_id", //ok
        name:"lawfirmnewcase",
        // component:NewCase,
        component:lazyload("lawfirm/NewCase"),
        meta:{title:"Case Management | Case Action"},
        props:true
    },
    {
        path:"/lawfirm/:campaign_id/case/:case_id/listing/:listing_id/edit", //ok
        name:"lawfirmeditcaselisting",
        // component:EditCaseListing,
        component:lazyload("lawfirm/EditCaseListing"),
        meta:{title:"Case Management | Edit Case Listing"},
        props:true
    },
    {
        path: "/lawfirm/exportToken/:exportToken", //ok
        name: "lawfirmexport",
        component: lazyload("lawfirm/Download"),
        meta: { title: "Case Management | Getting ready to download" },
        props: true
    }, 
    {
        path: "/lawfirm/notifications", //ok
        name:"lawfirmnotifications",
        component:lazyload("lawfirm/Notifications"),
        meta: {title:"Case Management | Notifications"}
    }, 
    {
        path: "/lawfirm/frozen-account-compiler", //ok
        name:"lawfirmfrozenaccountcompiler",
        component:lazyload("lawfirm/FrozenAccountCompiler"),
        meta: {title:"Case Management | Frozen Account Compiler"}
    },
    // commented out, not needed yet Dev: Marvin - 02/01/2021
    {
        path:"/lawfirm/counterfeit-checker", //ok
        name:'lawfirmcounterfeitchecker',
        component:lazyload("lawfirm/CounterfeitChecker"),
        meta:{title:"Case Management | Counterfeit Checker"}
    },
    // commented out, not needed yet Dev: Marvin - 02/01/2021
    {
        path:"/lawfirm/counterfeit-checker/:campaign_id", //ok
        name:'lawfirmcampaigncounterfeitchecker',
        component:lazyload("lawfirm/CampaignCounterfeitChecker"),
        meta:{title:"Case Management | Counterfeit Checker"}
    },
    {
        path:"/lawfirm/submissions-pack-monitoring", //ok
        name:'lawfirmsubmissionspackmonitoring',
        component:lazyload("lawfirm/SubmissionsStatusMonitoring"),
        meta:{title:"Case Management | Submissions Pack Status Monitoring"}
    }, // End of Lawfirm*/

    /*{ //For Admin
        path:"/admin",//ok
        name:"admin",
        component:lazyload("admin/auth/AdminLogin"),
        meta:{title:"Artemis | Admin"}
    },
    {
        path:"/admin/dashboard", //ok
        name:"admindashboard",
        component:lazyload("admin/Dashboard"),
        meta:{title:"Admin | Dashboard"}
    },
    {
        path:"/admin/rightsholder", //ok
        name:"adminrightsholder",
        component:lazyload("admin/Rightsholder"),
        meta:{title:"Admin | Rightsholder"}
    },
    {
        path:"/admin/rightsholder/:rightsholder_id/campaign", //ok
        name:"adminrightsholdercampaigns",
        component:lazyload("admin/RightsholderCampaign"),
        meta:{title:"Admin | Rightsholder Campaigns"}
    },
    {
        path:"/admin/campaigns", //ok
        name:"admincampaigns",
        component:lazyload("admin/Campaigns"),
        meta:{title:"Admin | Campaigns"}
    },
    {
        path:"/admin/campaigns/edit/:campaign_id", //ok
        name:"admineditcampaign",
        component:lazyload("admin/EditCampaign"),
        meta:{title:"Admin | Edit Campaign"}
    },
    {
        path:"/admin/platforms", //ok
        name:"adminplatforms",
        component:lazyload("admin/Platforms"),
        meta:{title:"Admin | Platforms"}
    },
    {
        path:"/admin/sellers", //ok
        name:"adminsellers",
        component:lazyload("admin/Sellers"),
        meta:{title:"Admin | Sellers"}
    },
    {
        path:"/admin/whitelists", //ok
        name:"adminwhitelists",
        component:lazyload("admin/Whitelists"),
        meta:{title:"Admin | Whitelisted Sellers"}
    },
    {
        path:"/admin/sellers/listings", //ok
        name:"adminsellerlistings",
        component:lazyload("admin/SellersListing"),
        meta:{title:"Admin | Sellers Listings"}
    },
    {
        path:"/admin/lf", //ok
        name:"adminlawfirms",
        component:lazyload("admin/Lawfirms"),
        meta:{title:"Admin | Lawfirms"}
    },
    {
        path:"/admin/lf/edit/:lawfirm_id", //ok
        name:"admineditlawfirm",
        component:lazyload("admin/EditLawfirm"),
        meta:{title:"Admin | Edit Lawfirm"}
    },
    {
        path:"/admin/lf/users", //route not found
        name:"adminlfusers",
        component:lazyload("admin/LfUser"),
        meta:{title:"Admin | Lawfirm Users"}
    },
    {
        path:"/admin/users", //ok
        name:"adminartemisusers",
        component:lazyload("admin/ManageUser"),
        meta:{title:"Admin | Artemis Users"}
    },
    {
        path:"/admin/users/teams", //ok
        name:"adminteams",
        component:lazyload("admin/ArtemisTeams"),
        meta:{title:"Admin | Artemis Teams"}
    },
    // {
    //     path:"/admin/settings",
    //     name:"adminmanagereports",
    //     component:lazyload("admin/ManageReport"),
    //     meta:{title:"Admin | Reports Management"}
    // }, 
    {
        path: "/admin/notifications", //ok
        name:"adminnotifications",
        component:lazyload("admin/Notifications"),
        meta: {title:"Admin | Notifications"}
    },
    {
        path:"/admin/settings/reasons", //ok
        name:"adminreasons",
        component: lazyload("admin/Reasons"),
        meta:{title:"Admin | Predefined Reasons"}
    },
    {
        path:"/admin/settings/classifications", //ok
        name:"adminitemclassification",
        component: lazyload("admin/ItemsClassifications"),
        meta:{title:"Admin | Item Classifications Management"}
    },
    {
        path:"/admin/settings/classifications/:classification_id", //ok
        name:"adminitemtypes",
        component: lazyload("admin/ItemTypesClassifications"),
        meta:{title:"Admin | Item Classifications Management"}
    },
    {
        path:"/admin/settings/dummies", //ok
        name:"admindummies",
        component: lazyload("admin/Dummies"),
        meta:{title:"Admin | Dummy Management"}
    },
    {
        path:"/admin/accounting-report", //ok
        name:"adminaccountingreports",
        component: lazyload("admin/CampaignAccountReportsSummary"),
        meta:{title:"Admin | Accounting Report"}
    },
    {
        path:"/admin/accounting-report/:campaign_id", //ok
        name:"admincampaignaccounting",
        component: lazyload("admin/CampaignAccountingReport"),
        meta:{title:"Admin | Campaign Accounting Report"}
    },
    {
        path:"/admin/documentation", //ok
        name:"admindocumentation",
        component: lazyload("admin/Documentation"),
        meta:{title:"Admin | End Points Documentation"}
    },
    {
        path:"/admin/error-logs", //ok
        name:"adminerrorlogs",
        component: lazyload("admin/ErrorLogs"),
        meta:{title:"Admin | Error Logs"}
    },
    {
        path:"/admin/settings/payment-methods", //ok
        name:"adminpaymentmethods",
        component: lazyload("admin/PaymentMethods"),
        meta:{title:"Admin | Payment Methods"}
    },
    {
        path:"/admin/settings/shipping-locations", //ok
        name:"adminshippinglocations",
        component: lazyload("admin/ShippingLocations"),
        meta:{title:"Admin | Shipping Locations"}
    },
    {
        path:"/admin/settings/infringement-indicators", //ok
        name:"admininfringementindicators",
        component: lazyload("admin/InfringementIndicators"),
        meta:{title:"Admin | Infringement Indicators"}
    },
    {
        path: "/admin/settings/defaults/evidence-order", //ok
        name: "admindefaultevidenceorder",
        component: lazyload("admin/Defaults"),
        meta:{title:"Admin | Evidence Order"}
    },
    {
        path: "/admin/settings/defaults/shipping-location", //ok
        name: "admindefaultshippinglocation",
        component: lazyload("admin/Defaults"),
        meta:{title:"Admin | Shipping Locations"}
    },
    {
        path: "/admin/settings/defaults/claim-type", //ok
        name: "admindefaultclaimtype",
        component: lazyload("admin/Defaults"),
        meta:{title:"Admin | Claim Types"}
    },
    {
        path: "/admin/environment-access-management", //ok
        name: "adminenvaccessmanagement",
        component: lazyload("admin/EnvironmentAccess"),
        meta:{title:"Admin | Environment Access Restriction Management"}
    }, 
    {
        path: "/admin/reports/researchers-output", //ok
        name: "adminresearchersoutput",
        component: lazyload("admin/ResearchersOutput"),
        meta:{title:"Admin | Researchers Output"}
    }, 
    {
        path: "/admin/reports/campaign-report", //ok
        name: "admincampaignreport",
        component: lazyload("admin/Reports"),
        meta:{title:"Admin | Campaign Report"}
    },
    {
        path: "/admin/reports/weekly-assignment", //ok
        name: "adminweeklyreport",
        component: lazyload("admin/ReportWeekly"),
        meta:{title:"Admin | Weekly Reports Assignment"}
    }, // End of Admin*/


    // For HTTP Pages
    // Verification
    /*{
        path:"/verification/whou",
        name:"vernosession",
        component: lazyload("NoSession"),
        meta:{title:"Verification | Session Expired"}
    },
    {
        path:"/verification/holdup",
        name:"vernotauthorized",
        component: lazyload("NotAuthorized"),
        meta:{title:"Verification | Not Authorized"}
    },
    {
        path:'/verification/404',
        name:'pagenotfound',
        // component:lazyload("verification/Search"),
        component:()=> import('./components/layouts/PageNotFound'),
        meta:{title:"Verification | Error 404"}
    },
    {
        path:"/verification/!*",
        redirect:"/verification/404"
    },*/

    // Lawfirm
    /*{
        path:"/lawfirm/whou",
        name:"lawfirmnosession",
        component: lazyload("NoSession"),
        meta:{title:"Case Management | Session Expired"}
    },
    {
        path:"/lawfirm/holdup",
        name:"lawfirmnotauthorized",
        component: lazyload("NotAuthorized"),
        meta:{title:"Case Management | Not Authorized"}
    },
    {
        path:'/lawfirm/404',
        name:'lawfirmpagenotfound',
        component:()=> import('./components/layouts/PageNotFound'),
        meta:{title:"Case Management | Error 404"}
    },
    {
        path:"/lawfirm/!*",
        redirect:"/lawfirm/404"
    },*/

    // Admin
    /*{
        path:"/admin/whou",
        name:"adminnosession",
        component: lazyload("NoSession"),
        meta:{title:"Admin | Session Expired"}
    },
    {
        path:'/admin/404',
        name:'adminpagenotfound',
        component:()=> import('./components/layouts/PageNotFound'),
        meta:{title:"Admin | Error 404"}
    },
    {
        path:"/admin/!*",
        redirect:"/admin/404"
    },*/
]

export default routes
