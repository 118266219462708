export default {
  INIT_THEME_AUDIO(state, value) {
      state.themeAudio = value
  },
  INIT_THEME_ADDITIONAL_AUDIO(state, value) {
    state.themeAdditionalAudio.push(value)
  },
  GET_PATH_NAME(state, value) {
    state.pathname = value
  }
}