import Case from '@/services/case';
import store from '@/store/index';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    all: [],
    loading: false,
    error: false
  },
  actions: {
    initialize({ commit }, campaignID) {
      commit('setLoading', true);
      commit('setError', false);
      commit('reset');
      
      Vue.prototype.$http
        .get(`cases/overviewV2/${campaignID}`, window.vm.$session.get('admin_headers'))
        .then(({data}) => {
          for (let _case of data) {
            commit('add', new Case(_case));
            store.commit("accountingInfo/add", _case);
            store.commit("fund/add", _case);
            store.dispatch("entity/setup", _case);
          }
        })
        .catch(ex => {
          console.log(ex);
          commit('setError', true);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    reset({ commit }, _case) {
      commit('reset');
      store.commit("accountingInfo/reset");
      store.commit("entity/reset");
      store.commit("fund/reset");
    }
  },
  mutations: {
    add(state, _case) {
      state.all.push(_case);
    },
    reset(state) {
      state.all = [];
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setError(state, error) {
      state.error = error;
    }
  },
};
