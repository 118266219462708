/* const listings = */
export default {
    checked_reasons     : [],
    checked_subreasons  : [],
    checked_other_reason: [],
    other_reason_comment: '',
    checked_commission  : null,
    no_seller_attached  : false,
    existing_seller     : 0,
    potentialWhitelists : [],
    dateCaptured        : ''
    //Add new state here
}

/* //const newStateHere = {}

export default {
    ...listings,
    //...newStateHere
} */