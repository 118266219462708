import AsyncModel from './asyncModel';

export default class Campaign extends AsyncModel {
    static entity = 'campaigns';

    constructor(model, config) {
        super(model, config);
    }

    static fields() {
        return {
            id: this.number(null),
            name: this.string(null)
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/campaigns',
        dataKey: 'data'
    }
}