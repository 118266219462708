import { getGuiSession } from '@/utils/session';
import AsyncModel from './asyncModel';
import Campaign from './campaign';
import VuexStore from '@/store/index';

export default class ClientListing extends AsyncModel {
    static entity = 'client_listings';

    constructor(model, config) {
        super(model, config);
    }

    static fields() {
        return {
            id: this.number(null),
            employee_user_id: this.number(null),
            campaign_id: this.number(null),
            listing_id: this.number(null),
            campaign: this.belongsTo(Campaign, "campaign_id", "id"),
            status: this.string(null)
        }
    }

    static apiConfig = {
        baseURL: 'api/v2/client_listings',
        dataKey: 'data'
    }

    static async loadAssignedListings() {
        if (VuexStore.state.entities[this.entity].initialized || VuexStore.state.entities[this.entity].loading) {
            return;
        }

        await ClientListing.api().get("", {
            params: {
                filter: {
                    'listing.user_id': getGuiSession()?.id,
                    'listing.qflag_id': [0, 4]
                },
                include: "campaign"
            }
        });
    }
}