export default class Case {
  constructor(_case) {
    this.id = _case.id;
    this.campaignID = _case.campaign_id;
    this.caseNumber = _case.case_number;
    this.jurisdiction = _case.jurisdiction;
    this.judge = _case.judge;
    this.dateFiled = _case.date_filed;
    this.troIssueDate = _case.date_granted;
    this.pioIssueDate = _case.date_pio_issued;
  }
}
