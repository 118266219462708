import moment from 'moment';
import axios from '../axiosMime';
import verification from './verification/mutations'
import holidayTheme from './holiday_theme/mutations';
import counterfeitchecker from './cm/counterfeitchecker/mutations';
import qs from 'qs'
import { buildHeader } from '@/utils/common';
import { useFeatureAccessStore } from './pinia/feature-access';

export default {
    SET_USERNAME(state,username){
        state.username = username
    },
    SET_NAVBAR(state,value){
        state.show_navbar = value
    },
    SET_SIDEBAR(state,value){
        state.show_sidebar = value
    },
    SET_FILTERDRAWER(state,value){
        state.show_filterdrawer = value
    },
    SET_GREYBG(state, value) {
        state.grey_bg = value
    },
    SET_NAVTYPE(state,value){
        state.navbar_type = value
    },
    SET_ADDEDTYPE(state, value){
        state.manually_added = value
    },
    SET_SELLERID(state,value){
        state.seller_id = value
    },
    SET_LISTINGID(state, value){
        state.listing_id = value
    },
    SET_CAMPAIGNS(state,campaigns){
        state.campaigns = campaigns
    },
    SET_FILTERS(state, filters){
        state.filters = filters
    },
    DISPLAY_LISTINGINFO(state,value){
        state.show_listinginfo = value
    },
    DISPLAY_EVIDENCES(state,value){
        state.show_evidence = value
    },
    ENABLE_TABS(state, obj){
        let {key, value} = obj
        state.tabs[key].disabled = value
    },
    ALLOW_EXIT(state, value){
        state.exit_manual_addition = value
    },
    SET_ACTIVE_LISTING_STATUS(state, value) {
        state.is_active_list_ready = value
    },
    GET_ACTIVE_LISTING(state, listing_info){
        state.active_listing_info  = listing_info
        state.is_active_list_ready = true
    },
    ALLOW_RESEARCHED(state,bool){
        state.is_researchable = bool
    },
    ALLOW_SAVE_EXIT(state, bool){
        state.allow_exit = bool
    },
    ENABLE_DISABLED(state, data) {
        state.is_disable = data
    },
    UPDATE_SHOWN(state, value) {
        state.shown_data = value
    },
    CHANGE_SHOW_ERROR(state) {
        state.show_error = !state.show_error;
    },
    GET_NOTIFICATIONS(state, notifications) {
        let oldest = state.notifications.length > 0 ? state.notifications[state.notifications.length - 1].id : null;
        let unloaded = [];
        for (let i = notifications.length - 1; i >= 0; i--) {
            if (oldest !== null && notifications[i].id >= oldest) {
                break;
            }
            
            notifications[i].moment_time = moment(notifications[i].created_at).format("MM/DD/YYYY LT");
            unloaded.unshift(notifications[i]);
        }
        state.notifications.push(...unloaded);
        if (notifications.length > 0) {
            state.page_notification++;
        }

        if(state.external_notification_listener !== undefined && typeof state.external_notification_listener === 'function') {
            state.external_notification_listener(unloaded);
        }
    },
    PREPEND_NOTIFICATION(state, response)  {
        state.notifications.unshift(...response);
        if(state.external_notification_listener !== undefined && typeof state.external_notification_listener === 'function') {
            state.external_notification_listener(response);
        }
    },
    ADD_NOTIFICATION_LISTENER(state, callback) {
        state.external_notification_listener = callback;

    },
    CLEAR_NOTIFICATIONS(state) {
        state.notifications = [];
    },
    SET_ADM_FD_STATUS(state){
        state.curr_adm_fd_status
    },
    SET_MANUAL_ADDITION_ERRORS(state, error){
        if(!state.manual_addition_errors.includes(error))
            state.manual_addition_errors.push(error)
    },
    REDUCE_MANUAL_ADDITION_ERRORS(state, error){
        state.manual_addition_errors = state.manual_addition_errors.filter(f=> f != error)
    },
    STORE_FEATURE_ACCESS(state, value) {
        state.feature_access = value
    },
    SET_SELLER_REFERENCE(state, seller) {
        state.seller_reference = seller
    },
    SET_LISTING_REFERENCE(state, listing) {
        state.listing_reference = listing
    },
    RESET_POST_FILING_FILTERS(state, value) {
        state.filters = value
    },
    INCREMENT_VIEW_KEY(state) {
        state.view_key++
    },
    SET_SIDEBAR_CAMPAIGNS(state, campaigns) {
        state.sidebar_campaigns = campaigns
    },
    REDUCE_SIDEBAR_CAMPAIGN_COUNT(state, campaign_name) {
        state.sidebar_campaigns = state.sidebar_campaigns.map(campaign => {
            campaign.campaign == campaign_name && (campaign.count--)
            return campaign
        })
    },
    REFRESH_CANCEL_TOKEN(state) {
        state.cancellationTokenSource = axios.CancelToken.source()
    },
    CANCEL_REQUESTS(state) {
        state.cancellationTokenSource.cancel()
    },
    ...verification,
    ...holidayTheme,
    ...counterfeitchecker,
    // added for Checking if done uploading Case File
    uploadedCaseFile(state, payload) {
        state.hasUploadedCaseFile = payload
    },
    deletedCaseFile(state, payload) {
        state.hasDeletedCaseFile = payload
    },
    // setFiled_listings(state, payload) {
    //     if(payload == true){
    //         let queries = {
    //             filter: {
    //                 campaign_id: this.$route.params.campaign_id,
    //                 qflag_id: 34,
    //                 include: ['campaign', 'user', 'info', 'preview', 'platform','mainAccount', 'preFilingGroups', 'seller.accounts', 'seller.platform', 'qualifier', 'case'],
    //                 page_size: 10000
    //             }
    //         }
    //         let response = axios.get(
    //             `${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encode: false })}`,
    //                 {
    //                     headers: buildHeader()
    //                 }
    //         )
    //         state.filed_listings = response.data.data.map(listing => ({
    //             campaign: listing.campaign.name,
    //             campaign_id: listing.campaign_id,
    //             campaign_image: getImage('campaigns', listing.campaign.name),
    //             claim_type: null,
    //             created_at: listing.created_at,
    //             evidences: {
    //                 Preview: listing.preview && listing.preview[0] && `${listing.id}/${listing.preview[0].file_name}`,
    //             },
    //             for_commission: listing.for_commission,
    //             group_name: listing.pre_filing_groups && listing.pre_filing_groups[0] && listing.pre_filing_groups[0].name,
    //             id: listing.id,
    //             key_id: this.getRandomKey(),
    //             listing_info: {
    //                 date_captured: this.getListingInfoValue('date_captured', listing),
    //                 item_type: this.getListingInfoValue('item_type', listing),
    //                 listing_title: this.getListingInfoValue('listing_title', listing)
    //             },
    //             name_email: listing.main_account && listing.main_account.name_email,
    //             number: listing.main_account && listing.main_account.number,
    //             platform: listing.platform && listing.platform.name,
    //             platform_id: listing.platform_id,
    //             platform_image: listing.platform && getImage('platforms', listing.platform.name),
    //             qflag_id: listing.qflag_id,
    //             qualifier: listing.qualifier && getUserFullName(listing.qualifier),
    //             seller: [{
    //                 name: listing.seller && listing.seller.name,
    //                 url: listing.seller && listing.seller.url,
    //                 site_id: listing.seller && listing.seller.site_id
    //             }],
    //             status: this.$route.params.status,
    //             type: listing.main_account && listing.main_account.type,
    //             url: listing.url,
    //             user_id: listing.user_id,
    //             verifier_owner: {
    //                 first_name: listing.user && listing.user.first_name,
    //                 last_name: listing.user && listing.user.last_name
    //             },
    //             main_account: listing.main_account,
    //             case: listing.case
    //         }));
    //     }else {
    //         state.filed_listings = []
    //     }
    // },
    isGlobalSearch(state, payload) {
        state.hasUsed_globalSearch = payload
    },
    globalSearchItem(state, payload) {
        state.globalSearch_input = payload
    },
    setGlobalSearch(state, payload) {
        state.globalSearch_count = payload
    },
    setGlobalListings(state, payload) {
        state.globalSearch_listings = payload
    },
    getPrev_route(state, payload) {
        state.previous_route = payload
    },
    setKeywords(state, payload) {
        state.keywords = payload
    },
    setReassignClientListings(state, payload) {
        state.hasReassign_clientListings = payload
    },
    async showClientListings(state, payload) {
        console.debug("triggered showClientListings in mutation.js", payload);
        const store = useFeatureAccessStore();
        console.debug({store, from: 'showClientListings'});
        await store.isFeatureRestrictionsReady;

        if (store.restrictedFeatures.includes('v-modal-client-listings')) {
            console.debug("showClientListings is restricted");
            state.show_clientListings = false;
            return;
        }

        console.debug("showClientListings is not restricted");
        state.show_clientListings = payload
    },
    setListing_has_clientListings(state, payload) {
        state.Listing_has_clientListings = payload
    },
    getClient_listings(state, payload) {
        state.client_Listings = payload
    },
    setClient_listings_manualAddition(state, payload) {
        state.client_Listings_manualAddition = payload
    },
    setAddNewGroupListing(state, payload) {
        state.addNewGroupListing = payload
    },
    setResearchedTracker(state, payload) {
        state.showResearchedTracker = payload
    },
    setCongratulatoryMsg(state, payload) {
        state.setCongratulatoryMsg = payload
    },
    setResearcherTrackerLoad(state, payload) {
        state.researchTrackerLoad = payload
    },
    setWaitingTracker(state, payload) {
        state.waitingTracker = payload
    },
    getUserAssignments(state, payload) {
        state.currentAssignedCampaign = payload
    },
    setTrigger(state, payload) {
        /**
         * accepts payload as
         * {
         *  label: string,
         *  value: boolean,
         * }
         * 
         *  Input the triggers here
         *  and use it to respective trigger points
         */
        let triggers = [
            'isTriggerOn_cancelEditListing', // done
            'isTriggerOn_saveAndExitEditListing', // done
            'isTriggerOn_cancelManualAddition', // done
            // 'isTriggerOn_addListing', // partial done - will be catered to saveAndExit Trigger
            'isTriggerOn_changeStatusFilters', // done
            'isTriggerOn_switchingCampaigns', // done -> Applied on Sidebar Campaigns
            'isTriggerOn_reassigningResearcherSide', // done -> Listing reassign
            'isTriggerOn_taskPageStatusChange', // done
            'isTriggerOn_verGUIListingStatusChange', // done
            'isTriggerOn_clientListingsModal', // done
        ]
        // console.log(payload)
        // console.log(state)
        if(triggers.includes(payload.label)) {
            // state[payload.label] = payload.value
            if(payload.value == true) {
                state.hasTriggeredSomething = payload.value
                // console.log('setHasTriggeredSomething to true')
            }
        }
        // console.log(payload.label.includes(triggers))
        // console.log(triggers.includes(payload.label))
        // console.log(state[payload?.label])
    },
    setHasTriggeredSomething(state, payload) {
        state.hasTriggeredSomething = payload
    },
    setRelatedCampaignDuplicates(state, payload) {
        state.relatedCampaignDuplicates = payload 
    },
    setRelatedCampaignDuplicateBypass(state, payload) {
        state.relatedCampaignBypass.allow = payload
    },
    setRelatedCampaignDuplicateContinueFunction(state, payload) {
        state.relatedCampaignBypass.continueFunction = payload
    },
    updatePaymentAccountsToBeAdded(state, payload) {
        switch (payload.action) {
            case "add":
                state.paymentAccountsToBeAdded.push(payload.account)
                break;
            case "reset":
                state.paymentAccountsToBeAdded = []
                break;
            case "remove":
                state.paymentAccountsToBeAdded = state.paymentAccountsToBeAdded.filter(p => p.id != payload.account.id)
                break;
            default:
                break;
        }
    },
    setUserQuota(state, payload) {
        state.weekly_quota = payload
    },
    setUserProfile(state, payload) {
        state.user_profile_vuex = payload
    },
    increaseUserCurrentWeeklyQuota(state, payload) {
        state.weekly_quota.current += 1
    },
    getCaseSellerAccount(state, payload) {
        state.case_seller_accounts_vuex = payload
    },
    getPlatformBreakdown2(state, payload) {
        state.lf_platforms_vuex2 = payload
    }
}

// const isRestrictedWithLFC = async () => {
//     const { headers, id } = JSON.parse(localStorage.getItem('ver'));
//     const { status, data } = await axios.get(`${process.env.VUE_APP_URL}/api/user-restricted-features?filter[user_id]=${id}&include=feature`, JSON.parse(headers));

//     if (status !== 200) {
//         throw Error('Something went wrong while calling /api/user-restricted-features!');
//     }

//     const { data: userRestrictedFeatures } = data;

//     if (!userRestrictedFeatures.length) {
//         return false;
//     }

//     /** @type { string[] } */
//     const classnames = userRestrictedFeatures.map(item => item.feature.class_name);

//     return classnames.includes('v-modal-client-listings');
// }
