/* const listings = */
export default {
    CHECK_REASON(state, reason) {
        state.checked_reasons = !state.checked_reasons.some(item => item.id === reason.id)
            ? [...state.checked_reasons, reason]
            : state.checked_reasons.filter(item => item.id !== reason.id)
    },
    CHECK_SUBREASON(state, subreason) {
        const isCheckedAlready = state.checked_subreasons.filter(({id}) => id === subreason.id).length
        state.checked_subreasons = isCheckedAlready
            ? state.checked_subreasons.filter(({id}) => id !== subreason.id)
            : [...state.checked_subreasons, subreason]
    },
    CHECK_OTHER_REASON(state, other) {
        state.checked_other_reason = !state.checked_other_reason.includes(other)
            ? [...state.checked_other_reason, other]
            : state.checked_other_reason.filter(item => item !== other)
    },
    INPUT_OTHER_REASON_COMMENT(state, comment) {
        state.other_reason_comment = comment
    },
    CHECK_COMMISSION(state, commission) {
        state.checked_commission = commission
    },
    RESET_PREDEFINED_REASON_STATE(state) {
        state.checked_reasons      = []
        state.checked_subreasons   = []
        state.checked_other_reason = ''
        state.other_reason_comment = ''
        state.checked_commission   = 0
    },
    SET_NO_SELLER_ATTACHED(state, bool) {
        state.no_seller_attached = bool
    },
    SET_EXISTING_SELLER(state, id) {
        state.existing_seller = id
    },
    SET_POTENTIAL_WHITELISTS(state, whitelists) {
        state.potentialWhitelists = whitelists
    },
    /**
     * Update any key of state dynamically.
     * obj should be a format of {nameOfState: value}
     * e.g. {dateOfCaptured: '08/18/2021'}
     */
    UPDATE_STATE(state, obj) {
        const [key, value] = Object.entries(obj)[0]
        state[key] = value
    }
}

/* //const newMutationsHere = {}

export default {
    ...listings,
    //newMutationsHere
} */
