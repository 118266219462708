import { Model } from '@vuex-orm/core';
import Platform from './platform';

export default class PlatformMatchingPattern extends Model {
    static entity = 'platform_matching_patterns';

    static fields() {
        return {
            platform_id: this.attr(null),
            host_pattern: this.string(null),
            platform: this.belongsTo(Platform)
        }
    }
}