export default class AccountingInfo {
  constructor(_case) {
    this.case_id = _case.id;
    this.totalDefendants = _case.totalDefendants;
    this.totalFrozenAmount = _case.totalFrozenAmount;
    this.totalAgreedSettlementAmount = _case.totalAgreedSettlementAmount;
    this.totalPaypalAmount = _case.totalPaypalAmount;
    this.totalWishAmount = _case.totalWishAmount;
    this.totalAmazonAmount = _case.totalAmazonAmount;
    this.totalExpectedSettlementAmount = _case.totalExpectedSettlementAmount;
    this.totalPredictedDefaultAmount = _case.totalPredictedDefaultAmount;
    this.totalDefendantsSettled = _case.totalDefendantsSettled;
    this.totalDefendantsDefaulted = _case.totalDefendantsDefaulted;
    this.totalCreditCTDADefaultAmount = _case.totalCreditCTDADefaultAmount;
  }
}
